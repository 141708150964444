import omit from "lodash/omit";
import { EditButton, ShowButton, WrapperField } from "react-admin";
import { UserManagerApi } from "../api/UserManagerApi";
import MyCreate from "../components/MyCreate";
import MyEdit from "../components/MyEdit";
import { MyList } from "../components/MyList";
import { UnLockButton } from "../components/UnLockButton";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import React from "react";
import { fields as usersFields } from "./User";
// import { fields } from "./User";
import { MyField } from "../components/types";
import { User } from "../model/User";

const fields: MyField<User>[] = usersFields.map((mf: MyField<User>) => {
  let f = Object.assign({}, mf);
  if (
    f.source === "isAgent" ||
    f.source === "isAccountLock" ||
    f.source === "isMyUser" ||
    f.source === "phone" ||
    f.source === "inviteCode"
  ) {
    f.isFilter = false;
  }
  if (f.source === "uid") {
    f.colSpan = ({ row }) => {
      if (row.id === undefined) {
        return 13;
      }
      return undefined;
    };
  }
  return f;
});

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        tableActions={
          <WrapperField>
            {/*<EditButton />*/}
            <UnLockButton
              onClick={async (r: any) => UserManagerApi.unlock(r.id)}
            />
          </WrapperField>
        }
        actions={false}
        rowActions={[]}
        getTreeDataPath={(r: any) => r.path}
        perPage={1000}
      />
    );
  },
  icon: PermContactCalendarIcon,
};

export default resource;
