import isUndefined from "lodash/isUndefined";
import { MyProDataGrid } from "./MyProDataGrid";
import React from "react";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridRowTreeNodeConfig } from "@mui/x-data-grid";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { RaRecord } from "ra-core";
import { MyField } from "./types";

export interface MyDatagridProps<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  getTreeDataPath?: (row: RecordType) => string[];
  rowActions: React.ReactElement<GridActionsCellItemProps>[];
  isGroupExpandedByDefault?: (node: GridRowTreeNodeConfig) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
}

export function MyDatagrid<RecordType extends RaRecord = any>(
  props: MyDatagridProps<RecordType>
) {
  const { renderActionCell, rowActions, fields, ...rest } = props;
  return (
    <MyProDataGrid
      size="small"
      renderActionCell={renderActionCell}
      bulkActionButtons={<></>}
      rowActions={rowActions}
      getTreeDataPath={props.getTreeDataPath}
      isGroupExpandedByDefault={props.isGroupExpandedByDefault}
      groupingColDef={props.groupingColDef}
      fields={fields.filter(
        (f) => (f.isList || isUndefined(f.isList)) && f.component
      )}
    ></MyProDataGrid>
  );
}
