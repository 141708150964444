import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { required, TextInput } from "react-admin";
import MyTextField from "../../components/MyTextField";

export interface MaxTextArgs {
  label: string;
  source: string;
}

export function maxText(args: MaxTextArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,
      isFilter: false,
      fullWidth: true,
      component: MyTextField,
      editComponent: TextInput,
      editProps: {
        validate: [required()],
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
