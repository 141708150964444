import { HttpError } from "react-admin";
import { AccountStorage } from "../AccountStorage";

export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers || new Headers()) as Headers;
  requestHeaders.append("Accept", "application/json");
  if (
    !requestHeaders.has("Content-Type") &&
    !(options && (!options.method || options.method === "GET")) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set("Content-Type", "application/x-www-form-urlencoded");
  }
  let admin = AccountStorage.get();
  if (admin && admin.token) {
    requestHeaders.set("Authorization", admin.token);
  }

  return requestHeaders;
};

export const fetchJson = (
  url: RequestInfo | URL,
  options: Options = {}
): Promise<{ headers: Headers; data: any; body: string; status: number }> => {
  const requestHeaders = createHeadersFromOptions(options);
  return fetch(url, {
    mode: "cors",
    ...options,
    headers: requestHeaders,
  })
    .then((response) => {
      return response.text().then((text) => {
        return {
          status: response.status,
          statusText: response.statusText,
          headers: response.headers,
          body: text,
        };
      });
    })
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        return Promise.reject(
          new HttpError((json && json.message) || statusText, status, json)
        );
      }
      if (json.ok !== true) {
        return Promise.reject(
          new HttpError((json && json.msg) || statusText, status, json)
        );
      }
      return Promise.resolve({ status, headers, body, data: json["data"] });
    });
};
