import isNumber from "lodash/isNumber";
import { isString } from "lodash";
import toNumber from "lodash/toNumber";

export function toString(v: number | undefined) {
  if (isString(v)) {
    v = toNumber(v);
  }
  if (!isNumber(v)) {
    return "";
  }
  return v.toFixed(2) + " ¥";
}

export function toStringNotSymbol(v: number) {
  if (isString(v)) {
    v = toNumber(v);
  }
  if (!isNumber(v)) {
    return "";
  }
  return v
    .toFixed(2)
    .replace(/.00$/g, "")
    .replace(/(\.[0-9])0$/g, "$1");
}

export const stringToNumber = (
  value: string,
  rate: number | undefined = undefined
) => {
  if (value == null) {
    return null;
  }
  if (isNumber(value)) {
    return value * (rate || 1);
  }
  const float = parseFloat(value.toString().replace(" ¥", ""));
  return isNaN(float) ? 0 : float * (rate || 1);
};
