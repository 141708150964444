import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { required, SelectArrayInput } from "react-admin";
import MyArrayField from "../../components/MyArrayField";

export interface EditArrayArgs {
  label: string;
  source: string;
  transform?: (record?: any) => any;
  // transformToChoices?: (record?: any) => any;
}

export function editArray(args: EditArrayArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,

      isFilter: false,
      component: MyArrayField,
      editComponent: SelectArrayInput,
      props: {
        transform: args.transform,
      },
      editProps: {
        validate: [required()],
      },
    },
    field
  );
}
