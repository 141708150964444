import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useGetList } from "react-admin";
import dayjs from "dayjs";
import { AddRobotLine } from "./components/AddRobotLine";
import { RobotLineList } from "./components/RobotLineList";
import { RobotLineLogList } from "./components/RobotLineLogList";

export interface RobotLineTableProps {}

export function RobotLineTable(props: RobotLineTableProps) {
  const { data: exchangeCoins, total, isLoading, error } = useGetList(
    "exchangeCoins",
    {
      pagination: { page: 1, perPage: 199 },
    }
  );
  const [time, setTime] = useState(dayjs());

  function onChange() {
    setTime(dayjs());
  }

  return (
    <Stack>
      <AddRobotLine
        onChange={onChange}
        exchangeCoins={exchangeCoins || []}
        isLoading={isLoading}
      />
      <RobotLineList
        time={time}
        onChange={onChange}
        exchangeCoins={exchangeCoins || []}
      />
      <RobotLineLogList
        time={time}
        onChange={onChange}
        exchangeCoins={exchangeCoins || []}
      />
    </Stack>
  );
}
