import { MyField } from "../../components/types";
import { SelectInput } from "react-admin";
import { MySelectField } from "../../components/MySelectField";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";

export interface SelectArgs extends BaseArgs {
  choices?: any[];
}

const defaultArgs: Partial<TextArgs> = {
  isRequired: true,
  isFilter: false,
};

export function select(args: SelectArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MySelectField,
      editComponent: SelectInput,
      editProps: {
        choices: args?.choices,
      },
      props: {
        choices: args?.choices,
        alwaysOn: true,
      },
      filterProps: {
        alwaysOn: true,
        choices: args?.choices,
      },
    },
    field
  );
}
