import React, { ReactElement } from "react";
import {
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  ListProps,
  NumberField,
  RefreshButton,
  SimpleShowLayout,
  TextField,
  TopToolbar,
  useResourceDefinition,
} from "react-admin";
// import MobileGrid from "./MobileGrid";
import { RaRecord } from "ra-core";
import isUndefined from "lodash/isUndefined";
import { ExcludeProps, MyField } from "./types";
import { MyProDataGrid } from "./MyProDataGrid";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import omit from "lodash/omit";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { GridRowTreeNodeConfig } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { Box, Grid, Stack } from "@mui/material";

export interface MyShowFieldsProp<RecordType extends RaRecord = any> {
  fields: MyField<RecordType>[];
}

const MyShowFields = <RecordType extends RaRecord = any>(
  props: MyShowFieldsProp<RecordType>
) => {
  const { fields } = props;
  return (
    <SimpleShowLayout>
      {fields
        .filter((f) => (f.isList || isUndefined(f.isList)) && f.component)
        .map((field: MyField, index) => {
          const {
            component: FieldComponent,
            source,
            label,
            props,
            sortable = false,
          } = field;

          if (!FieldComponent) {
            return <></>;
          }

          return (
            <Stack direction={"row"} key={`${index}`}>
              <Box width={"140px"}>{label}:</Box>
              <Box>
                <FieldComponent
                  source={source}
                  label={label}
                  sortable={sortable}
                  {...props}
                />
              </Box>
            </Stack>
          );
        })}
    </SimpleShowLayout>
  );
};

export { MyShowFields };
