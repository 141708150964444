export enum UserWalletType {
  /**
   * 现货
   */
  SPOT = "SPOT",
  /**
   * 杠杆
   */
  LEVERAGE = "LEVERAGE",

  /**
   * 合约
   */
  CONTRACT = "CONTRACT",
}

export const UserWalletTypeChoices = [
  { id: "SPOT", name: "现货" },
  { id: "LEVERAGE", name: "杠杆" },
  { id: "CONTRACT", name: "合约" },
];

export interface UserWallet {
  id: number;
  userId: number;
  userName: string;
  coinId: number;

  type: UserWalletType;
  coinSymbol: string;
  balance: string;
  frozenBalance: string;

  address: Record<string, string>;

  isLock: boolean;

  createdDate: string;
  updateDate: string;
  version: number;
}
