export type RobotLineRunStatus = "NOT_STARTED" | "IN_PROGRESS" | "FINISHED";

export const RobotLineRunStatusChoices = [
  {
    id: "NOT_STARTED",
    name: "未开始",
  },
  { id: "IN_PROGRESS", name: "进行中" },
  { id: "FINISHED", name: "已结束" },
];

export type ExchangeOrderType = "LIMIT" | "MARKET";

export const ExchangeOrderTypeChoices = [
  { id: "LIMIT_PRICE", name: "限价单" }, // 限价单
  { id: "MARKET_PRICE", name: "市价单" }, // 市价单
];

export type ExchangeOrderDirection = "BUY" | "SELL";

export const ExchangeOrderDirectionChoices = [
  { id: "BUY", name: "买入" }, // 买入
  { id: "SELL", name: "卖出" }, // 卖出
];

export type ExchangeOrderStatus = [
  "INIT",
  "TRADING",
  "COMPLETED",
  "CANCELED",
  "OVERTIMED",
  "CLOSED"
];

export const ExchangeOrderStatusChoices = [
  { id: "INIT", name: "初始化" }, // 初始化
  { id: "TRADING", name: "交易中" }, // 交易中
  { id: "COMPLETED", name: "已成交" }, // 已完成
  { id: "CANCELED", name: "已取消" }, // 已取消
  { id: "OVERTIMED", name: "已超时" }, // 已超时
  { id: "CLOSED", name: "已平仓" }, // 已平仓
];
