import dayjs from "dayjs";
import { useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import {
  doRobotLineClear,
  doRobotLineStop,
  findAllNotStop,
} from "../../../service/adminRobotLine/api/adminRobotLineService";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

type RobotLineListProps = {
  exchangeCoins: any[];
  time: dayjs.Dayjs;
  onChange: () => void;
};

export function RobotLineList({
  exchangeCoins,
  time,
  onChange,
}: RobotLineListProps) {
  const notify = useNotify();
  const [list, setList] = useState<API.RobotLine[]>([]);
  async function clear(symbol: string) {
    try {
      await doRobotLineClear({ symbol });
      notify("提交成功", { autoHideDuration: 5000, type: "success" });
    } catch (e) {
      notify("错误" + e, { autoHideDuration: 5000, type: "success" });
    } finally {
      onChange();
    }
  }
  async function stop(symbol: string) {
    try {
      await doRobotLineStop({ symbol });
      notify("提交成功", { autoHideDuration: 5000, type: "success" });
    } catch (e) {
      notify("错误" + e, { autoHideDuration: 5000, type: "success" });
    } finally {
      onChange();
    }
  }
  async function load() {
    const list = await findAllNotStop();
    setList(list.data || []);
  }
  useEffect(() => {
    load();
    let id = setTimeout(doLoader, 2000);
    async function doLoader() {
      await load();
      id = setTimeout(doLoader, 2000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [time]);

  return (
    <Card sx={{ marginTop: "16px" }}>
      <CardHeader title={"控线机器人"} />
      <CardContent>
        <List sx={{ maxWidth: 300 }}>
          {list?.map((item) => (
            <ListItem
              key={item.symbol}
              disablePadding
              secondaryAction={
                <Stack>
                  {item.runStatus === "IN_PROGRESS" && (
                    <>
                      <Button onClick={() => clear(item.symbol!!)}>清除</Button>
                      <Typography variant="body2" color="text.secondary">
                        运行中
                      </Typography>
                    </>
                  )}
                  {item.runStatus === "NOT_STARTED" && (
                    <>
                      <Button onClick={() => stop(item.symbol!!)}>清除</Button>
                      <Typography variant="body2" color="text.secondary">
                        未开始
                      </Typography>
                    </>
                  )}
                </Stack>
              }
            >
              <ListItemText
                primary={
                  <>
                    {item.symbol}({item.price})
                  </>
                }
                secondary={
                  <>
                    时间范围:{dayjs(item.startTime).format("HH:mm")} -{" "}
                    {dayjs(item.endTime).format("HH:mm")}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
