import { IconButton, Tooltip } from "@mui/material";
import * as React from "react";
import { TextFieldProps, useRecordContext } from "react-admin";
import { Customer } from "../types";
import { ContentCopy } from "@mui/icons-material";
import CopyToClipboard from "./CopyToClipboard";

interface ObjectIdFieldProps extends TextFieldProps {
  fullWidth: boolean | undefined;
  fontSize?: number | string;
}

const ObjectIdField = (props: ObjectIdFieldProps) => {
  const { fullWidth, fontSize = "0.9rem" } = props;
  const record = useRecordContext<Customer>();
  if (!record || !props.source) {
    return null;
  }
  const value = record[props.source];
  if (!value) {
    return null;
  }
  let str = `${value}`;
  const text = fullWidth ? str : str.substring(str.length - 6, str.length);

  return (
    <Tooltip title={`ObjectId: ${value}`}>
      <span style={{ fontSize: fontSize }}>
        {text}
        <CopyToClipboard>
          {({ copy }) => (
            <IconButton onClick={() => copy(value)}>
              <ContentCopy sx={{ fontSize: fontSize }} />
            </IconButton>
          )}
        </CopyToClipboard>
      </span>
    </Tooltip>
  );
};

ObjectIdField.defaultProps = {
  label: "ID",
  source: "id",
  fullWidth: false,
};

export default ObjectIdField;
