import * as React from "react";
import { useEffect, useState } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import { useRecordContext } from "ra-core";
import { Payments } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import {
  Box,
  Button as MuiButton,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import { adminChangeMargin } from "../../../service/adminMargin/api/adminMarginService";

export interface AmountButtonProps {}

export default function AmountButton(props: AmountButtonProps) {
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const record = useRecordContext();
  const [number, setNumber] = useState(record.number);
  const [level, setLevel] = useState(record.level);
  useEffect(() => {
    setNumber(record.number);
    setLevel(record.level);
  }, [record]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    refresh();
  };

  const onSubmit = async () => {
    await adminChangeMargin({
      marginId: record.id as string,
      direction: record.direction,
      number: number,
      level: level,
    });

    notify("操作成功");
    handleClose();
  };

  let label = "修改";
  return (
    <>
      <Button
        onClick={() => {
          setTimeout(() => {
            handleClickOpen();
          });
        }}
        label={label}
      >
        <Payments />
      </Button>
      {open && (
        <Dialog
          open={open}
          fullWidth
          maxWidth={"xs"}
          onClose={handleClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle id="scroll-dialog-title">{label}</DialogTitle>
          <DialogContent dividers>
            <Box component="form" id={"create"} onSubmit={() => onSubmit()}>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                    margin="dense"
                    size="medium"
                    id="url"
                    label="持仓量"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item flex={1}>
                  <TextField
                    required
                    autoFocus
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                    margin="dense"
                    size="medium"
                    id="url"
                    label="杠杆倍率"
                    type="number"
                    fullWidth
                    variant="standard"
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <MuiButton onClick={handleClose}>关闭</MuiButton>
            <MuiButton form={"create"} type="submit">
              确定
            </MuiButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
