import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import MemoryIcon from "@mui/icons-material/Memory";
import { formatDateTime } from "../../utils/formatDateTime";
import { BooleanTypeChoices, StatusTypeChoices } from "../../model/Core";
import { RobotLineRunStatusChoices } from "../../model/exchange";
import { RobotLineTable } from "./RobotLineTable";

const fields: MyField<API.Protocol>[] = [
  Field.objectId({}),
  Field.ref({
    label: "交易对",
    source: "exchangeCoinId",
    originSource: "exchangeCoinId",
    reference: "exchangeCoins",
    referenceLabel: "symbol",
  }),
  Field.editDate({
    label: "开始时间",
    source: "startTime",
  }),
  Field.editDate({
    label: "结束时间",
    source: "endTime",
  }),
  Field.editNumber({
    label: "价格",
    source: "price",
  }),
  Field.editNumber({
    label: "延迟恢复(分钟)",
    source: "delayMinusBack",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "createdDate",
  }),
  Field.editSelect({
    label: "状态",
    source: "status",
    choices: StatusTypeChoices,
  }),
  Field.select({
    label: "运行状态",
    source: "runStatus",
    choices: RobotLineRunStatusChoices,
  }),
];

const resource = {
  list: () => {
    return <RobotLineTable />;
  },
  create: () => {
    return (
      <MyCreate
        fields={fields}
        transform={(data: any, options?: { previousData: any }) => {
          return {
            ...data,
            endTime: formatDateTime(data.endTime),
            startTime: formatDateTime(data.startTime),
          };
        }}
      />
    );
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: MemoryIcon,
};

export default resource;
