import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";
import { NftGoodsStatusChoices } from "../../model/nft";
import { UnLockButton } from "../../components/UnLockButton";
import { UserManagerApi } from "../../api/UserManagerApi";
import { UpDownButton } from "./UpDownButton";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  {
    label: "上下架",
    source: "status1",
    component: WrapperField,
    isFilter: true,
    props: {
      children: <UpDownButton />,
    },
  },
  Field.functionField({
    render(record: any, source: string | undefined): any {
      return NftGoodsStatusChoices.find((item) => item.id === record.status)
        ?.name;
    },
    label: "状态",
    isEdit: false,
    source: "status",
  }),
  Field.ref({
    label: "分类",
    source: "categoryId",
    isRequired: false,
    isList: true,
    isFilter: false,
    reference: "nftCategories",
    referenceLabel: "title",
    originSource: "categoryId",
    translateLabel: (record: any) => record?.title?.[0].value,
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    fullWidth: true,
    isFilter: false,
  }),
  Field.ref({
    label: "协议",
    source: "protocolSymbol",
    reference: "protocols",
    referenceLabel: "symbol",
    originSource: "symbol",
  }),
  Field.editAmount({
    label: "价格(USDT)",
    source: "price",
  }),
  Field.img({
    label: "主图",
    source: "image",
  }),
  Field.text({
    label: "合约地址",
    source: "address",
  }),
  Field.editDate({
    label: "下架时间",
    source: "endDateTime",
  }),
  Field.richText({
    label: "正文",
    source: "content",
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
            <DeleteButton />
          </WrapperField>
        }
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: PhotoLibraryIcon,
};

export default resource;
