import polyglotI18nProvider from "ra-i18n-polyglot";
import { Admin, CustomRoutes, memoryStore, Resource } from "react-admin";
import { Route } from "react-router";

import Polyglot from "node-polyglot";
import authProvider from "./authProvider";
import { API_URL } from "./config";
import Configuration from "./configuration/Configuration";
import { MyDashboard } from "./dashboard";
import HttpDataProvider from "./dataProvider/HttpDataProvider";
import chineseMessages from "./i18n/ch";
import { Layout, Login } from "./layout";
import { lightTheme } from "./layout/themes";

import admins from "./pages/Admin";
import loginLogs from "./pages/LoginLog";
import users from "./pages/User";
import userByGroup from "./pages/UserByGroup";
import userWallets from "./pages/user/UserWallet";

import protocols from "./pages/coin/Protocol";
import coins from "./pages/coin/Coin";
import coinProtocols from "./pages/coin/CoinProtocol";

import exchangeCoins from "./pages/exchange/ExchangeCoin";
import exchangeOrders from "./pages/exchange/ExchangeOrder";
import exchangeTrades from "./pages/exchange/ExchangeTrade";
import zones from "./pages/exchange/Zone";

import nftCategories from "./pages/nft/NftCategory";
import nftGoods from "./pages/nft/NftGoods";
import nftOrders from "./pages/nft/NftOrder";

import fundTermPlans from "./pages/fund/FundTermPlans";
import fundTermUsers from "./pages/fund/FundTermUser";
import fundCurrentPlans from "./pages/fund/FundCurrentPlans";
import fundCurrentUsers from "./pages/fund/FundCurrentUser";

import robotLines from "./pages/exchange/RobotLine";
import { getMarginsResource } from "./pages/exchange/ExchangeMargin";

import docs from "./pages/content/Doc";
import webPages from "./pages/content/WebPage";
import docCategories from "./pages/content/DocCategory";

import settings from "./pages/setting/setting";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import telBotConfig from "./pages/setting/TelBotConfig";
import devSettings from "./pages/devSetting/devSettings";
import userWalletApply from "./pages/user/UserWalletApply";

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    return chineseMessages;
  },
  "ch",
  {
    onMissingKey: (
      key: string,
      options: Polyglot.InterpolationOptions,
      locale: string
    ) => key,
  }
);

// function NewComponent() {
//   let notify = useNotify();
//
//   console.log(notify);
//   debugger;
//
//   window.addEventListener("error", (error) => {
//     console.log("捕获到异常：", error.error);
//     notify(error.error);
//   });
//   window.addEventListener("unhandledrejection", (error) => {
//     console.log("捕获到异常：", error.reason.message);
//     notify(error.reason.message);
//   });
//
//   return <div></div>;
// }

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Admin
        title="管理后台"
        dataProvider={HttpDataProvider(API_URL)}
        authProvider={authProvider}
        dashboard={MyDashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
        disableTelemetry
        store={memoryStore()}
        theme={lightTheme}
      >
        <CustomRoutes>
          <Route path="/configuration" element={<Configuration />} />
        </CustomRoutes>
        <Resource name="admins" {...admins} />
        <Resource name="users" {...users} />
        <Resource name="userByGroup" {...userByGroup} />
        <Resource name="userWalletApply" {...userWalletApply} />
        <Resource name="protocols" {...protocols} />
        <Resource name="coins" {...coins} />
        <Resource name="coinProtocols" {...coinProtocols} />
        <Resource name="userWallets" {...userWallets} />
        <Resource name="loginLogs" {...loginLogs} />
        <Resource name="exchangeCoins" {...exchangeCoins} />
        <Resource name="exchangeOrders" {...exchangeOrders} />
        <Resource name="exchangeTrades" {...exchangeTrades} />
        <Resource name="zones" {...zones} />
        <Resource name="robotLines" {...robotLines} />
        <Resource
          name="exchangeMarginsLeverage"
          {...getMarginsResource("LEVERAGE")}
        />
        <Resource
          name="exchangeMarginsContract"
          {...getMarginsResource("CONTRACT")}
        />
        <Resource name="docs" {...docs} />
        <Resource name="webPages" {...webPages} />
        <Resource name="docCategories" {...docCategories} />
        <Resource name="settings" {...settings} />
        <Resource name="devSettings" {...devSettings} />

        <Resource name="nftCategories" {...nftCategories} />
        <Resource name="nftGoods" {...nftGoods} />
        <Resource name="nftOrders" {...nftOrders} />

        <Resource name="fundTermPlans" {...fundTermPlans} />
        <Resource name="fundTermUsers" {...fundTermUsers} />
        <Resource name="fundCurrentPlans" {...fundCurrentPlans} />
        <Resource name="fundCurrentUsers" {...fundCurrentUsers} />

        <Resource name="telBotConfigs" {...telBotConfig} />
      </Admin>
    </LocalizationProvider>
  );
};

export default App;
