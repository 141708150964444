import { BooleanInput, NumberInput, required } from "react-admin";
import React from "react";
import { format, parse } from "../../utils/percent";
import InputAdornment from "@mui/material/InputAdornment";

export const ExchangeSetting: React.FC = (props) => {
  return (
    <>
      <NumberInput
        label="盈亏倍率"
        source="setting.profitAndLossMult"
        validate={required()}
        parse={parse}
        format={format}
        step={1}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <NumberInput
        label="强制平仓保证金率"
        source="setting.forcedClosingMarginRate"
        validate={required()}
        parse={parse}
        format={format}
        step={0.01}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <NumberInput
        label="费率"
        source="setting.feeMult"
        validate={required()}
        parse={parse}
        format={format}
        step={0.01}
        InputProps={{
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
      <BooleanInput
        label="行情监控"
        source="setting.enableMarketMonitor"
        validate={required()}
      />
    </>
  );
};
