import SettingsIcon from "@mui/icons-material/Settings";
import React from "react";
import { Edit, SimpleForm, useRecordContext } from "react-admin";
import { ExchangeSetting } from "./ExchangeSetting";
import { SiteSetting } from "./SiteSetting";
import { BinanceSetting } from "./BinanceSetting";
import { EmailSetting } from "./EmailSetting";

const FormMap = {
  ExchangeSetting: ExchangeSetting,
  SiteSetting: SiteSetting,
  BinanceSetting: BinanceSetting,
  EmailSetting: EmailSetting,
};

const MyEditForm = () => {
  const record = useRecordContext();
  // @ts-ignore
  let Com = FormMap[record.id];
  return <SimpleForm>{Com && <Com />}</SimpleForm>;
};

export const MyEdit = () => {
  return (
    <Edit redirect={false} mutationMode={"pessimistic"}>
      <MyEditForm />
    </Edit>
  );
};

export default {
  edit: MyEdit,
  icon: SettingsIcon,
};
