import { MyField } from "../../components/types";
import {
  FunctionField,
  ReferenceArrayField,
  required,
  TextField,
  AutocompleteArrayInput,
  ReferenceArrayInput,
} from "react-admin";
import * as React from "react";
import { base, BaseArgs } from "./base";
import get from "lodash/get";

export interface RefArrArgs extends BaseArgs {
  /**
   * 引用表, 例如: "users"
   */
  reference: string;
  /**
   * 引用表显示字段, 例如: "name"
   */
  referenceLabel?: string;
  /**
   * 来源表映射字段, 例如: "userId"
   */
  originSource?: string;
  optionValue?: string;
  optionText?: string;
}

/**
 * Field.refArr({
 *     label: "限制币种",
 *     source: "symbolTradeLimitList",
 *     reference: "coins",
 *     referenceLabel: "symbol",
 *     originSource: "symbolTradeLimitList",
 *     optionValue: "symbol",
 *     isFilter: false,
 *   }),
 */

const defaultArgs: Partial<RefArrArgs> = {
  fullWidth: false,
  optionValue: "id",
  optionText: "name",
  isRequired: false,
};

export const refArr = (args: RefArrArgs, field?: MyField): MyField => {
  const {
    reference,
    originSource = args.source,
    optionValue = "id",
    optionText = "name",
  } = args;

  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: ReferenceArrayField,
      props: {
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        link: "show",
        children: (
          <ReferenceArrayField
            label={args.label}
            reference={reference}
            source={originSource}
          />
        ),
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        children: (
          <AutocompleteArrayInput
            optionValue={optionValue}
            optionText={optionText}
            label={`${args.label}`}
            name={args.source}
            // reference={reference}
            // source={originSource}
          />
        ),
      },
      editComponent: ReferenceArrayInput,
      editProps: {
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        name: args.source,
        children: (
          <AutocompleteArrayInput
            optionValue={optionValue}
            optionText={optionText}
            validate={args.isRequired ? [required()] : []}
            label={`${args.label}`}
            name={args.source}
            // reference={reference}
            // source={originSource}
          />
        ),
      },
    },
    field
  );
};
