import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { MyAmountField } from "../../components/MyAmountField";
import { NumberInput } from "react-admin";
import { stringToNumber, toStringNotSymbol } from "../amountUtils";

export interface EditAmountArgs {
  label: string;
  source: string;
  transform?: (record?: any) => any;
}

export function editAmount(args: EditAmountArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,
      isFilter: false,
      sortable: false,
      isCreate: true,
      isEdit: true,
      isUpdate: true,
      component: MyAmountField,
      editComponent: NumberInput,
      editProps: {
        min: 0,
        parse: (value: string) => {
          console.log("parse:", value);
          return stringToNumber(value);
        },
        format: (value: any) => {
          console.log("format:", value);
          return toStringNotSymbol(value);
        },
      },
    },
    field
  );
}
