import {
  SelectFieldProps,
  useNotify,
  useRefresh,
  useUpdate,
  WrapperField,
} from "react-admin";
import { useChoices, useRecordContext, useResourceContext } from "ra-core";
import * as React from "react";
import { useEffect, useState } from "react";
import get from "lodash/get";
import {
  Box,
  CircularProgress,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

interface EditSelectFieldProps extends SelectFieldProps {}

export function EditSelectField(props: EditSelectFieldProps) {
  const {
    className,
    emptyText,
    source,
    choices,
    optionValue,
    optionText,
    translateChoice,
    ...rest
  } = props;
  const notify = useNotify();
  const record = useRecordContext(props);
  const [loading, setLoading] = React.useState(false);

  const value = get(record, source as string);
  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });
  const [selectValue, changeSelectValue] = useState<any>(value);
  const refresh = useRefresh();

  useEffect(() => {
    const value = get(record, source as string);
    changeSelectValue(value);
  }, [record, source, selectValue]);

  const [update, { isLoading, error, isError }] = useUpdate();

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      notify(error["message"] || error + "", { type: "error" });
      refresh();
    }
  }, [error, isError, notify, refresh]);

  const resource = useResourceContext(props);
  const handleChange = async (event: SelectChangeEvent) => {
    setLoading(true);
    const r = event.target;
    const value = r.value;
    changeSelectValue(value);
    await update(resource, {
      id: record.id,
      data: { ...record, [source as string]: value },
      previousData: record,
    });
    setLoading(false);
  };
  return (
    <WrapperField>
      <Select<any>
        variant={"standard"}
        size="small"
        disabled={isLoading || loading}
        onChange={handleChange}
        value={selectValue}
      >
        {choices?.map((i, index) => (
          <MenuItem key={`${index}`} value={getChoiceValue(i)}>
            {getChoiceText(i)}
          </MenuItem>
        ))}
      </Select>
      {(isLoading || loading) && (
        <CircularProgress
          size={18}
          color="info"
          sx={{
            position: "absolute",
            top: "50%",
            left: "30%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </WrapperField>
  );
}
