import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import InsightsIcon from "@mui/icons-material/Insights";
import { ExchangeOrderDirectionChoices } from "../../model/exchange";
import { EditButton } from "react-admin";
import EditMarginButton from "./components/EditMarginButton";
import { CloseMarginButton } from "./components/CloseMarginButton";
import { MyDatagrid } from "../../components/MyDatagrid";
import { userField } from "../../utils/field/userField";
import { BooleanTypeChoices } from "../../model/Core";

const fields: MyField<API.Protocol>[] = [
  Field.objectId(),
  userField(),
  Field.text({
    label: "交易对",
    source: "info.symbol",
    isFilter: false,
  }),
  Field.select({
    label: "水军",
    source: "isMyUser",
    maxWidth: 50,
    isFilter: true,
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "交易方向",
    source: "direction",
    maxWidth: 70,
    choices: ExchangeOrderDirectionChoices,
    isFilter: false,
  }),
  Field.number({
    label: "持仓量",
    source: "number",
  }),
  Field.number({
    label: "杠杆倍率",
    source: "level",
  }),
  Field.amount({
    label: "保证金",
    source: "info.deposit",
  }),
  Field.amount({
    label: "用户余额",
    source: "balance",
  }),
  Field.amount({
    label: "开仓库均价",
    source: "info.avgPrice",
  }),
  Field.amount({
    label: "盈亏",
    source: "profit",
  }),
  Field.number({
    label: "收益率(%)",
    source: "profitRate",
  }),
  Field.percent({
    label: "保证金率(%)",
    source: "marginRate",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "info.createdDate",
  }),
];

type MarginsType = "LEVERAGE" | "CONTRACT";

export function getMarginsResource(marginType: MarginsType) {
  return {
    list: () => {
      return (
        <MyList
          filterDefaultValues={{ isMyUser: false }}
          filter={{ marginType: marginType }}
          fields={fields}
          rowActions={[<EditMarginButton />, <CloseMarginButton />]}
        />
      );
    },
    icon: InsightsIcon,
  };
}

export const MyMarginsTable = ({ marginType }: { marginType: MarginsType }) => {
  return (
    <MyDatagrid
      fields={fields}
      rowActions={[<EditMarginButton />, <CloseMarginButton />]}
    />
  );
};

export const icon = InsightsIcon;
