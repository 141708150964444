import VisitorIcon from "@mui/icons-material/People";
import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";

const fields: MyField<API.Protocol>[] = [
  Field.objectId({}),
  Field.text({
    label: "符号",
    source: "symbol",
  }),
  Field.text({
    label: "名称",
    source: "name",
  }),
  Field.text(
    {
      label: "地址验证正则",
      source: "addressReg",
    },
    {
      editProps: {
        validate: [],
      },
    }
  ),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
          </WrapperField>
        }
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: VisitorIcon,
};

export default resource;
