import { useRecordContext } from "ra-core";
import * as React from "react";
import { Button, ButtonProps, useNotify, useRefresh } from "react-admin";
import { marginClose } from "../../../service/adminMargin/api/adminMarginService";
import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import { delay } from "../../../utils/delay";

export type CloseMarginButtonProps = ButtonProps;

export const CloseMarginButton = (props: CloseMarginButtonProps) => {
  const record = useRecordContext(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    if (loading) return;
    setLoading(true);
    try {
      await marginClose({ marginId: record.id as string });
      notify("操作成功");
      await delay(1000);
      refresh();
    } finally {
      setLoading(false);
    }
  };

  if (!record) return null;

  return (
    <Button label={"关闭"} onClick={() => handleClick()}>
      <>
        {!loading && <CloseIcon />}
        {loading && <CircularProgress size={18} color="info" />}
      </>
    </Button>
  );
};

CloseMarginButton.defaultProps = { resource: "abc" };
