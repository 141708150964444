import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import WalletIcon from "@mui/icons-material/Wallet";
import { UserWalletApply } from "../../model/UserWalletApply";
import MyEdit from "../../components/MyEdit";
import { Button, useRecordContext } from "react-admin";
import { Dialog, DialogTitle } from "@mui/material";

export const fields: MyField<UserWalletApply>[] = [
  Field.objectId(
    {},
    {
      isList: false,
    }
  ),
  Field.editAmount({
    label: "成功取出金额",
    source: "completedAmount",
  }),
  Field.editAmount({
    label: "提交金额",
    source: "commitAmount",
  }),
  Field.editAmount({
    label: "等待金额",
    source: "waitAmount",
  }),
  Field.editAmount({
    label: "拒绝金额",
    source: "rejectAmount",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "最后一次更新时间",
    source: "updateDate",
  }),
];

const resource = {
  edit: (onSuccess: (data: any, variables: any) => void) => {
    return (
      <MyEdit
        resource={"userWalletApply"}
        fields={fields}
        mutationOptions={{ onSuccess: onSuccess }}
      />
    );
  },
  icon: WalletIcon,
};

export function EditUserApplyDialogBtn() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSuccess = (data: any, variables: any) => {
    handleClose();
  };

  // @ts-ignore
  return (
    <>
      <Button
        size="small"
        variant="contained"
        onClick={handleClickOpen}
        label={"编辑提现金额"}
      />
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle>编辑提现金额</DialogTitle>
        {resource.edit(onSuccess)}
      </Dialog>
    </>
  );
}

export default resource;
