import { MyField } from "../../components/types";
import {
  AutocompleteInput,
  FunctionField,
  ReferenceField,
  ReferenceInput,
  required,
  TextField,
} from "react-admin";
import * as React from "react";
import { base, BaseArgs } from "./base";
import get from "lodash/get";

export interface RefArgs extends BaseArgs {
  /**
   * 引用表, 例如: "users"
   */
  reference: string;
  /**
   * 引用表显示字段, 例如: "name"
   */
  referenceLabel?: string;
  /**
   * 来源表映射字段, 例如: "userId"
   */
  originSource?: string;
  translateLabel?: (record: any) => string;
  // referenceValueSource?: string;
}

/**
 *  Field.ref({
 *     label: "用户",
 *     source: "userId",
 *     reference: "users",
 *     referenceLabel: "name",
 *     referenceSource: "userId",
 *   })
 */

const defaultArgs: Partial<RefArgs> = {
  referenceLabel: "name",
  fullWidth: false,
};

export const ref = (args: RefArgs, field?: MyField): MyField => {
  const {
    reference,
    referenceLabel = "name",
    translateLabel,
    originSource = args.source,
    isRequired,
  } = args;

  let optionText = (choice?: any) => {
    if (translateLabel) {
      return translateLabel(choice);
    }
    let value = get(choice, referenceLabel);
    return value // the empty choice is { id: '' }
      ? `${value}`
      : "";
  };
  return base(
    {
      ...defaultArgs,
      ...args,
      isRequired: false,
    },
    {
      component: ReferenceField,
      props: {
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        link: "show",
        children: translateLabel ? (
          <FunctionField
            label="Name"
            render={(record: any) => translateLabel(record)}
          />
        ) : (
          <TextField source={referenceLabel} />
        ),
      },
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        children: (
          <AutocompleteInput
            label={`${args.label}`}
            optionText={optionText}
            name={args.source}
          />
        ),
      },
      editComponent: ReferenceInput,
      editProps: {
        source: originSource,
        // valueSource: referenceValueSource,
        reference: reference,
        name: args.source,
        children: (
          <AutocompleteInput
            validate={isRequired ? [required()] : []}
            label={`${args.label}`}
            optionText={optionText}
            name={args.source}
          />
        ),
      },
    },
    field
  );
};
