import { MyField } from "../../components/types";
import { base, BaseArgs } from "./base";
import { MyLocalesValueField } from "../../components/MyLocalesValueField";
import { MyRichTextInput } from "../../components/MyRichTextInput";

export interface MyRichTextArgs extends BaseArgs {}

const defaultArgs: Partial<MyRichTextArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function richText(args: MyRichTextArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyLocalesValueField,
      editComponent: MyRichTextInput,
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
