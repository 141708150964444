import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import FactCheckIcon from "@mui/icons-material/FactCheck";

const fields: MyField<API.Coin>[] = [
  Field.objectId(),
  Field.text({
    label: "交易对",
    source: "symbol",
  }),
  Field.ref({
    label: "买单用户",
    source: "buyUserId",
    reference: "users",
    referenceLabel: "name",
    originSource: "buyUserId",
  }),
  Field.ref({
    label: "卖单用户",
    source: "sellUserId",
    reference: "users",
    referenceLabel: "name",
    originSource: "sellUserId",
  }),
  Field.amount({
    label: "单价格",
    source: "trade.price",
  }),
  Field.number({
    label: "数量",
    source: "trade.number",
  }),
  Field.ref({
    label: "买单",
    source: "trade.buyOrderId",
    reference: "exchangeOrders",
    referenceLabel: "id",
    originSource: "trade.buyOrderId",
  }),
  Field.ref({
    label: "卖单",
    source: "trade.sellOrderId",
    reference: "exchangeOrders",
    referenceLabel: "id",
    originSource: "trade.sellOrderId",
  }),
  Field.amount({
    label: "成交额",
    source: "trade.volume",
  }),
  Field.dateTime({
    label: "时间",
    source: "trade.time",
  }),
];

const resource = {
  list: () => {
    return <MyList fields={fields} rowActions={[]} tableActions={<></>} />;
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: FactCheckIcon,
};

export default resource;
