export enum NftGoodsStatus {
  UP = "UP",
  DOWN = "DOWN",
  SELL = "SELL",
}

export const NftGoodsStatusChoices = [
  { id: NftGoodsStatus.UP, name: "上架" },
  { id: NftGoodsStatus.DOWN, name: "下架" },
  { id: NftGoodsStatus.SELL, name: "已售" },
];
