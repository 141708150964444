import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import {
  FundStrategiesTypeChoices,
  FundTermPlanStatusChoices,
  FundTermTypeChoices,
} from "../../model/Found";
import { BooleanTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.objectId(),
  Field.select({
    label: "状态",
    source: "status",
    isEdit: false,
    choices: FundTermPlanStatusChoices,
  }),
  Field.editSelect({
    label: "是否开启",
    source: "enable",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否可以赎回",
    source: "enableRedeem",
    choices: BooleanTypeChoices,
  }),
  Field.select({
    label: "类型",
    source: "type",
    choices: FundTermTypeChoices,
  }),
  Field.select({
    label: "策略类型",
    source: "strategiesType",
    choices: FundStrategiesTypeChoices,
  }),
  Field.text({
    label: "币种",
    source: "coinSymbol",
    isRequired: true,
  }),
  Field.percent({
    label: "年化收益率",
    source: "yieldRate",
    isRequired: true,
  }),
  Field.editNumber({
    label: "精度",
    source: "coinScale",
  }),
  Field.editNumber({
    label: "排序",
    source: "sort",
  }),
  Field.editDate({
    label: "开始购买时间",
    source: "startBuyDate",
  }),
  Field.editDate({
    label: "结束购买时间",
    source: "endBuyDate",
  }),
  Field.editDate({
    label: "记息日",
    source: "startROIDate",
  }),
  Field.editDate({
    label: "结束日期",
    source: "endDate",
  }),
  Field.editDate({
    label: "收益发放时间",
    source: "postingDate",
  }),
  Field.editAmount({
    label: "最低认购数量",
    source: "minLimit",
  }),
  Field.editAmount({
    label: "总限制",
    source: "allLimit",
  }),
  Field.amount({
    label: "已售",
    source: "allNumber",
  }),
  Field.editAmount({
    label: "单用户限制",
    source: "userLimit",
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    fullWidth: true,
    isFilter: false,
  }),
  Field.richText({
    label: "正文",
    source: "content",
    fullWidth: true,
    isFilter: false,
  }),
  Field.richText({
    label: "问题解答",
    source: "faq",
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
            <DeleteButton />
          </WrapperField>
        }
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: CurrencyExchangeIcon,
};

export default resource;
