import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { required, SelectInput } from "react-admin";
import { EditSelectField } from "../../components/EditSelectField";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";
import { MySelectField } from "../../components/MySelectField";

export interface EditSelectArgs extends BaseArgs {
  choices?: any[];
}

const defaultArgs: Partial<EditSelectArgs> = {
  isRequired: true,
};

export function editSelect(args: EditSelectArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: EditSelectField,
      editComponent: SelectInput,
      props: {
        choices: args?.choices,
        alwaysOn: true,
      },
      filterProps: {
        alwaysOn: true,
        choices: args?.choices,
      },
      editProps: {
        validate: [required()],
        choices: args.choices,
      },
    },
    field
  );
}
