import { Typography } from "@mui/material";
import get from "lodash/get";
import { FC, memo } from "react";
import {
  NumberFieldProps,
  sanitizeFieldRestProps,
  useRecordContext,
} from "react-admin";
import { toString } from "../utils/amountUtils";
import isUndefined from "lodash/isUndefined";

export const MyAmountField: FC<NumberFieldProps> = memo((props) => {
  const {
    className,
    emptyText,
    source,
    locales,
    options,
    textAlign,
    ...rest
  } = props;
  const record = useRecordContext(props);
  if (!record) {
    return null;
  }
  if (!source) {
    return null;
  }
  const value = get(record, source);
  if (value == null || isUndefined(value)) {
    return emptyText ? (
      <Typography
        component="span"
        variant="body2"
        className={className}
        {...sanitizeFieldRestProps(rest)}
      >
        {emptyText}
      </Typography>
    ) : null;
  }

  return (
    <Typography
      variant="body2"
      component="span"
      className={className}
      {...sanitizeFieldRestProps(rest)}
    >
      {toString(value)}
    </Typography>
  );
});

MyAmountField.defaultProps = {
  textAlign: "right",
};
