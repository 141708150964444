import { TextInput } from "react-admin";
import React from "react";
import { MyArrayInput } from "../../components/MyArrayInput";

export const BinanceSetting: React.FC = (props) => {
  return (
    <>
      <MyArrayInput
        fullWidth={true}
        label={"api 地址"}
        source={"setting.apiUrls"}
      />
      <MyArrayInput
        fullWidth={true}
        label={"websocket 地址"}
        source={"setting.wsUrls"}
      />
    </>
  );
};
