import React, { ReactElement } from "react";
import {
  CreateButton,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  List,
  ListProps,
  RefreshButton,
  TopToolbar,
  useResourceDefinition,
} from "react-admin";
// import MobileGrid from "./MobileGrid";
import { RaRecord } from "ra-core";
import isUndefined from "lodash/isUndefined";
import { ExcludeProps, MyField } from "./types";
import { MyProDataGrid } from "./MyProDataGrid";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import omit from "lodash/omit";
import {
  GridGroupingColDefOverride,
  GridGroupingColDefOverrideParams,
} from "@mui/x-data-grid-pro/models/gridGroupingColDefOverride";
import { GridRowParams, GridRowTreeNodeConfig } from "@mui/x-data-grid";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";

export interface MyListProp<RecordType extends RaRecord = any>
  extends Omit<ListProps<RecordType>, "children"> {
  renderActionCell?: (
    params: GridRenderCellParams<any, any, any>
  ) => React.ReactNode;
  fields: MyField<RecordType>[];
  rowActions?: React.ReactElement<GridActionsCellItemProps>[];
  tableActions?: ReactElement | false;
  getTreeDataPath?: (row: RecordType) => string[];
  isGroupExpandedByDefault?: (node: GridRowTreeNodeConfig) => boolean;
  groupingColDef?:
    | GridGroupingColDefOverride<RecordType>
    | ((
        params: GridGroupingColDefOverrideParams
      ) => GridGroupingColDefOverride<RecordType> | undefined | null);
  getDetailPanelContent?: (
    params: GridRowParams<RecordType>
  ) => React.ReactNode;
  perPage?: number;
}

const DEFAULT_TABLE_ACTIONS = [<EditButton />, <DeleteWithConfirmButton />];

const DEFAULT_TABLE_ACTIONS_NOT_EDIT = [<DeleteWithConfirmButton />];

const MyList = <RecordType extends RaRecord = any>(
  props: MyListProp<RecordType>
) => {
  const { hasCreate: isCreate, hasEdit: isUpdate } = useResourceDefinition();

  const {
    fields,
    tableActions,
    renderActionCell,
    rowActions = isUpdate
      ? DEFAULT_TABLE_ACTIONS
      : DEFAULT_TABLE_ACTIONS_NOT_EDIT,
    getDetailPanelContent,
    perPage = 25,
    ...rest
  } = props;

  // const isXSmall = useMediaQuery<Theme>((theme) =>
  //   theme.breakpoints.down("sm")
  // );

  const ListActions = () => (
    <TopToolbar>
      <FilterButton />
      {isCreate && <CreateButton />}
      <ExportButton />
      <RefreshButton />
    </TopToolbar>
  );

  const visitorFilters = fields
    .filter((f) => f.isFilter && f.editComponent != null)
    .map((field: MyField, index) => {
      const {
        editComponent: FieldComponent,
        source,
        label,
        filterProps,
      } = field;
      if (!FieldComponent) {
        return <></>;
      }
      return (
        <FieldComponent
          key={`${index}`}
          source={source}
          label={label}
          {...omit(filterProps, ExcludeProps)}
        />
      );
    });
  return (
    <List
      empty={false}
      filters={visitorFilters}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions />}
      perPage={perPage}
      {...rest}
    >
      <MyProDataGrid
        size="small"
        renderActionCell={renderActionCell}
        bulkActionButtons={<></>}
        rowActions={rowActions}
        getTreeDataPath={props.getTreeDataPath}
        isGroupExpandedByDefault={props.isGroupExpandedByDefault}
        groupingColDef={props.groupingColDef}
        fields={fields.filter(
          (f) => (f.isList || isUndefined(f.isList)) && f.component
        )}
        getDetailPanelContent={getDetailPanelContent}
      ></MyProDataGrid>
    </List>
  );
};

export { MyList };
