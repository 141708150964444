import { isElement } from "lodash";
import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { RecordContextProvider } from "react-admin";
import { findDOMNode } from "react-dom";
import { Button } from "@mui/material";

export type MyProActionButtonProps = {
  row: any;
  button: ReactElement;
};

export const MyProActionButton = (props: MyProActionButtonProps) => {
  const { row, button, ...other } = props;
  const [actionDom, setActionDom] = useState<Element | undefined>();
  const buttonRef = useRef(null);
  const clonedElement = React.cloneElement(button);

  const [icon, setIcon] = useState<string | undefined>();

  const [label, setLabel] = useState<string | undefined>(button.props.label);

  useEffect(() => {
    let buttonDom = findDOMNode(buttonRef.current);
    if (!buttonDom) {
      return;
    }
    if (!icon) {
      // @ts-ignore
      let list = buttonRef?.current?.children?.[0]?.children;
      if (list && list.length > 1) {
        setIcon(list[0].outerHTML);
        // @ts-ignore
        setLabel(buttonDom["querySelector"]("a,button").innerText);
      }
    }

    if (isElement(buttonDom)) {
      // @ts-ignore
      let actionDom = buttonDom["querySelector"]("a,button");
      setActionDom(actionDom as Element);
    }
  }, [buttonRef, buttonRef.current]);

  return (
    <>
      <Button
        size={"small"}
        onClick={(e) => {
          if (buttonRef.current) {
            // @ts-ignore
            setTimeout(() => actionDom?.click?.(), 0);
          }
          e.stopPropagation();
        }}
        {...(other as any)}
      >
        <RecordContextProvider<any> value={row}>
          <div
            style={{ paddingTop: "4px", height: "22px" }}
            dangerouslySetInnerHTML={{ __html: icon as string }}
          />
          {label}
        </RecordContextProvider>
      </Button>
      <span
        ref={buttonRef}
        style={{
          visibility: "visible",
          width: 1,
          height: 1,
          overflow: "hidden",
          position: "absolute",
        }}
      >
        <RecordContextProvider<any> value={row}>
          {clonedElement}
        </RecordContextProvider>
      </span>
    </>
  );
};
