import { SelectFieldProps, WrapperField } from "react-admin";
import { useChoices, useRecordContext } from "ra-core";
import * as React from "react";
import get from "lodash/get";
import { Box } from "@mui/material";
import toString from "lodash/toString";

interface EditSelectFieldProps extends SelectFieldProps {}

export function MySelectField(props: EditSelectFieldProps) {
  const { source, choices, optionValue, optionText, translateChoice } = props;
  const record = useRecordContext(props);

  const { getChoiceText, getChoiceValue } = useChoices({
    optionText,
    optionValue,
    translateChoice,
  });

  const value = toString(get(record, source as string));

  const item = choices?.find((i) => {
    const curValue = getChoiceValue(i);
    return toString(curValue) === toString(value);
  });

  return (
    <WrapperField>
      <Box>{item && getChoiceText(item)}</Box>
    </WrapperField>
  );
}
