import { useInput } from "ra-core";
import * as React from "react";
import { Button, CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import {
  Card,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { LocalesType, toLocaleName } from "../model/Core";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Tier } from "../model/Found";
import { format, parse } from "../utils/percent";
import InputAdornment from "@mui/material/InputAdornment";
import { isEmpty } from "lodash";

export interface MyTiersInputProps extends CommonInputProps {
  className?: string;
}

export function MyTiersInput(props: MyTiersInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    helperText,
    onBlur,
    format: _format,
    parse: _parse,
    onChange,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format: _format,
    parse: _parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { name, ref } = field;
  const value: Tier[] = field.value || [];

  function changeValue(index: number, name: string, v: string) {
    const newValue = [...value];
    if (isEmpty(v)) {
      let tier = { ...newValue[index] };
      // @ts-ignore
      delete tier[name];
      newValue[index] = tier;
      field.onChange(newValue);
    } else {
      newValue[index] = { ...newValue[index], [name]: v };
      field.onChange(newValue);
    }
  }

  function add() {
    const newValue = [...value, {}];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...value];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box p={"8px 0"}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        {value.map((item, index) => {
          return (
            <Card
              key={index}
              sx={{ m: "8px", p: "8px", gap: "8px", display: "flex" }}
            >
              <TextField
                label={"最高额度"}
                size={"small"}
                variant={"outlined"}
                type={"number"}
                value={item.maxValue}
                onChange={(e) => changeValue(index, "maxValue", e.target.value)}
              />
              <TextField
                label={"年化收益率"}
                size={"small"}
                variant={"outlined"}
                type={"number"}
                value={format(item.yieldRate)}
                onChange={(e) =>
                  changeValue(index, "yieldRate", parse(e.target.value) + "")
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
              <TextField
                label={"最高年化收益率"}
                size={"small"}
                variant={"outlined"}
                type={"number"}
                value={format(item.yieldRateMax)}
                onChange={(e) =>
                  changeValue(index, "yieldRateMax", parse(e.target.value) + "")
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            </Card>
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        <Button
          size={"medium"}
          onClick={() => add()}
          startIcon={<AddCircleIcon />}
          label={"添加"}
        />
      </Box>
    </Box>
  );
}
