import * as React from "react";
import { useMemo } from "react";
import { GridActionsCellItemProps } from "@mui/x-data-grid/components/cell/GridActionsCellItem";
import { GridRenderCellParams } from "@mui/x-data-grid-pro";
import { MyProActionButton } from "./MyProActionButton";
import { Button, ButtonGroup, Menu, Theme, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { MyGridActionsCellItem } from "./MyProActionMenuItem";
import { isEmpty } from "lodash";

type Item = React.ReactElement<GridActionsCellItemProps>;

interface MyProActionsProps {
  rowActions: Item[];
  params: GridRenderCellParams<any, any, any>;
}

const options = [
  "None",
  "Atria",
  "Callisto",
  "Dione",
  "Ganymede",
  "Hangouts Call",
  "Luna",
  "Oberon",
  "Phobos",
  "Pyxis",
  "Sedna",
  "Titania",
  "Triton",
  "Umbriel",
];

const ITEM_HEIGHT = 48;

export const MyProActions = (props: MyProActionsProps) => {
  const { rowActions, params } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [menuItems, buttons] = useMemo(() => {
    const menuItems: Item[] = [];
    const buttons: Item[] = [];
    if (isXSmall) {
      menuItems.push(...rowActions);
    } else {
      rowActions.forEach((action, i) => {
        if (i > 1) {
          menuItems.push(action);
        } else {
          buttons.push(action);
        }
      });
    }
    return [menuItems, buttons];
  }, [rowActions, isXSmall]);

  return (
    <div>
      <ButtonGroup variant="outlined" size={"small"}>
        {buttons.map((item, i) => (
          <MyProActionButton key={`${i}`} button={item} row={params.row} />
        ))}
        {!isEmpty(menuItems) && (
          <Button onClick={handleClick}>
            <MoreVertIcon fontSize={"small"} />
          </Button>
        )}
      </ButtonGroup>
      {!isEmpty(menuItems) && (
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
          {menuItems.map((item, i) => (
            <MyGridActionsCellItem
              key={`${i}`}
              button={item}
              row={params.row}
            />
          ))}
        </Menu>
      )}
    </div>
  );
};
