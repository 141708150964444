import { Tooltip } from "@mui/material";
import Chip, { ChipProps } from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import isArray from "lodash/isArray";
import { memo } from "react";
import {
  FieldProps,
  sanitizeFieldRestProps,
  useRecordContext,
} from "react-admin";
import { Customer } from "../types";
import { entries, isObject } from "lodash";

// interface MyArrayFieldProps{
// }

export interface MyArrayFieldProps
  extends FieldProps,
    Omit<ChipProps, "label"> {
  transform?: (record?: any) => any;
}

// render: (record?: RecordType, source?: string) => any;

const PREFIX = "RaChipField";

const ChipFieldClasses = {
  chip: `${PREFIX}-chip`,
};

const StyledChip = styled(Chip, {
  name: PREFIX,
  overridesResolver: (props, styles) => styles.root,
})({
  [`&.${ChipFieldClasses.chip}`]: { margin: 1, cursor: "inherit" },
});

const MyArrayField = memo((props: MyArrayFieldProps) => {
  const { className, source, transform = (r) => r, ...rest } = props;
  const record = useRecordContext<Customer>();
  if (!record || !props.source) {
    return <></>;
  }
  const value = record[props.source];
  if (!value || !(isArray(value) || isObject(value))) {
    return <></>;
  }
  let array: any[];
  if (isArray(value)) {
    array = value;
  } else {
    array = entries(value);
  }
  if (array.length > 2) {
    return (
      <>
        {array.slice(0, 2).map((item, index) => (
          <StyledChip
            key={item}
            className={clsx(ChipFieldClasses.chip, className)}
            label={transform(item)}
            {...sanitizeFieldRestProps(rest)}
          />
        ))}
        <Tooltip title={`${array.map((i) => transform(i)).join(", ")}`}>
          <StyledChip
            className={clsx(ChipFieldClasses.chip, className)}
            label={"..."}
            {...sanitizeFieldRestProps(rest)}
          />
        </Tooltip>
      </>
    );
  }

  return (
    <>
      {array.map((item, index) => (
        <StyledChip
          key={item}
          className={clsx(ChipFieldClasses.chip, className)}
          label={transform(item)}
          {...sanitizeFieldRestProps(rest)}
        />
      ))}
    </>
  );
});

export default MyArrayField;
