import dayjs from "dayjs";
import { useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import {
  clearRobotLineKline,
  doRobotLineClear,
  doRobotLineStop,
  findAllNotStop,
  findAllRobotLinkLogs,
} from "../../../service/adminRobotLine/api/adminRobotLineService";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

type RobotLineLogListProps = {
  exchangeCoins: any[];
  time: dayjs.Dayjs;
  onChange: () => void;
};

export function RobotLineLogList({
  exchangeCoins,
  time,
  onChange,
}: RobotLineLogListProps) {
  const notify = useNotify();
  const [list, setList] = useState<API.RobotLineLog[]>([]);
  async function stop(robotId: string) {
    try {
      await clearRobotLineKline({ robotId });
      notify("提交成功", { autoHideDuration: 5000, type: "success" });
    } catch (e) {
      notify("错误" + e, { autoHideDuration: 5000, type: "success" });
    } finally {
      onChange();
    }
  }
  async function load() {
    const list = await findAllRobotLinkLogs();
    setList(list.data || []);
  }
  useEffect(() => {
    load();
  }, [time]);

  return (
    <Card sx={{ marginTop: "16px" }}>
      <CardHeader title={"k线历史记录"} />
      <CardContent>
        <List sx={{ maxWidth: 300 }}>
          {list?.map((item) => (
            <ListItem
              key={item.symbol}
              disablePadding
              secondaryAction={
                <Stack>
                  <>
                    <Button onClick={() => stop(item.id!!)}>清除</Button>
                  </>
                </Stack>
              }
            >
              <ListItemText
                primary={
                  <>
                    {item.symbol}({item.price})
                  </>
                }
                secondary={
                  <>
                    时间范围:{dayjs(item.startTime).format("YYYY-MM-DD HH:mm")}{" "}
                    - {dayjs(item.endTime).format("HH:mm")}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
