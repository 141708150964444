import { base, BaseArgs } from "./base";
import { MyField } from "../../components/types";
import { MyTiersInput } from "../../components/MyTiersInput";
import { FunctionField } from "react-admin";
import { format } from "../percent";
import get from "lodash/get";

export interface TiersArgs extends BaseArgs {}

const defaultArgs: Partial<TiersArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function tiers(args: TiersArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: FunctionField,
      props: {
        textAlign: "left",
        render: (record: any, source: string) => {
          let v = get(record, source as string);
          return JSON.stringify(v);
        },
      },
      editComponent: MyTiersInput,
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
