// @ts-ignore
/* eslint-disable */
import { request } from '../../../utils/request';

/** 此处后端没有提供注释 POST /fundTermUser/distributeIncome/${param0} */
export async function distributeIncome(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.distributeIncomeParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<boolean>(`/fundTermUser/distributeIncome/${param0}`, {
    method: 'POST',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
