import { API_URL } from "../config";
import { isEmpty, isString } from "lodash";
import { fetchJson } from "./fetch";
import qs from "qs";
import { formatDateTime } from "./formatDateTime";

export interface Result<T> {
  headers: Headers;
  data: T;
  body: string;
  status: number;
}

export async function request<T>(
  path: String,
  options?: { [key: string]: any }
): Promise<Result<T | undefined>> {
  let reqUrl = `${API_URL}` + path;
  let requestInit = {
    ...options,
  };
  if (options && options.data && !isString(options.data)) {
    // requestInit.body = new URLSearchParams(options.data);
    requestInit.body = qs.stringify(options.data, {
      allowDots: true,
      skipNulls: true,
      serializeDate: (d: Date) => formatDateTime(d),
    });
  }
  if (options && !isEmpty(options.params)) {
    let u = new URL(reqUrl);
    // u.search = new URLSearchParams(options.params).toString();
    u.search = qs.stringify(options.params, {
      allowDots: true,
      skipNulls: true,
      serializeDate: (d: Date) => formatDateTime(d),
    });
    reqUrl = u.toString();
  }
  return await fetchJson(reqUrl, requestInit);
}
