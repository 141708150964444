import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import StoreIcon from "@mui/icons-material/Store";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isFilter: false,
  }),
  Field.text({
    label: "名称",
    source: "name",
    isFilter: false,
  }),
  Field.localesValue({
    label: "显示名称",
    source: "localeName",
    isFilter: false,
  }),
  Field.editNumber({
    label: "排序",
    source: "sort",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
            <DeleteButton />
          </WrapperField>
        }
      />
    );
  },
  create: () => {
    return (
      <MyCreate
        fields={fields}
        transform={(data: any, options?: { previousData: any }) => {
          console.log(data, options);
          return {
            ...data,
          };
        }}
      />
    );
  },
  edit: () => {
    return (
      <MyEdit
        fields={fields}
        transform={(data: any, options?: { previousData: any }) => {
          console.log(data, options);
          return {
            ...data,
          };
        }}
      />
    );
  },
  icon: StoreIcon,
};

export default resource;
