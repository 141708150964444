import { MyField } from "../../components/types";
import { TextField, TextInput } from "react-admin";
import { base, BaseArgs } from "./base";

export interface TextArgs extends BaseArgs {}

const defaultArgs: Partial<TextArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function text(args: TextArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: TextField,
      editComponent: TextInput,
      filterProps: {
        alwaysOn: true,
        clearAlwaysVisible: true,
        resettable: true,
      },
    },
    field
  );
}
