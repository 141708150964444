// @ts-ignore
/* eslint-disable */
import { request } from '../../../utils/request';

/** 此处后端没有提供注释 POST /robotLine/all */
export async function findAllNotStop(options?: { [key: string]: any }) {
  return request<API.RobotLine[]>('/robotLine/all', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /robotLine/clearKline */
export async function clearRobotLineKline(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.clearRobotLineKlineParams,
  options?: { [key: string]: any },
) {
  return request<boolean>('/robotLine/clearKline', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /robotLine/doClear */
export async function doRobotLineClear(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.doRobotLineClearParams,
  options?: { [key: string]: any },
) {
  return request<API.RobotLine>('/robotLine/doClear', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /robotLine/logs */
export async function findAllRobotLinkLogs(options?: { [key: string]: any }) {
  return request<API.RobotLineLog[]>('/robotLine/logs', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /robotLine/stop */
export async function doRobotLineStop(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.doRobotLineStopParams,
  options?: { [key: string]: any },
) {
  return request<API.RobotLine>('/robotLine/stop', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
