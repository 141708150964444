import { Field } from "../Field";

export function userField() {
  return Field.ref({
    label: "用户",
    source: "userId",
    reference: "users",
    referenceLabel: "uid",
    originSource: "userId",
    minWidth: 140,
    translateLabel: (v: any) => {
      return (v.isMyUser ? "*" : "") + `${v.name}(${v.uid})`;
    },
  });
}
