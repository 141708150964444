import {
  RaRecord,
  ReferenceFieldClasses,
  ReferenceFieldProps,
} from "react-admin";
import * as React from "react";
import { LinkToType, useCreatePath, useRecordContext } from "ra-core";
import get from "lodash/get";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";

export type ReferenceToFunctionType<
  RecordType extends Record<string, any> = Record<string, any>
> = (record: RecordType) => string;

/**
 * 因为是在本地 RecordType 里面获取link的属性的方式,所以link使用 RecordType
 */
interface LocalReferenceFieldProps<
  RecordType extends RaRecord = RaRecord,
  ReferenceRecordType extends RaRecord = RaRecord
> extends Omit<
    ReferenceFieldProps<RecordType, ReferenceRecordType>,
    "reference" | "link"
  > {
  localLabelSource: string;
  reference: string | ReferenceToFunctionType<RecordType>;
  link?: LinkToType<RecordType>;
}

const stopPropagation = (e: any) => e.stopPropagation();

export function LocalReferenceField<
  RecordType extends RaRecord = RaRecord,
  ReferenceRecordType extends RaRecord = RaRecord
>(props: LocalReferenceFieldProps<RecordType, ReferenceRecordType>) {
  const { localLabelSource, source, reference, link } = props;

  const createPath = useCreatePath();

  const record = useRecordContext<RecordType>(props);
  const id = get(record, source);
  const child = get(record, localLabelSource);

  if (link) {
    const curResource =
      typeof reference === "function" ? reference(record) : reference;

    const resourceLinkPath = createPath({
      resource: curResource,
      id,
      type: typeof link === "function" ? link(record, curResource) : link,
    });
    return (
      <Link
        to={resourceLinkPath as string}
        className={ReferenceFieldClasses.link}
        onClick={stopPropagation}
      >
        {child}
      </Link>
    );
  }
  return (
    <Typography component="span" variant="body2">
      {child}
    </Typography>
  );
}

LocalReferenceField.defaultProps = {
  link: "edit",
};
