import * as React from "react";
import { useEffect } from "react";
import { Layout, LayoutProps, useNotify } from "react-admin";
import AppBar from "./AppBar";
import Menu from "../Menu";

export default (props: LayoutProps) => {
  let notify = useNotify();

  useEffect(() => {
    let errorListener = (error: ErrorEvent) => {
      console.log("捕获到异常：", error.error);
      notify(error.error, { type: "error" });
    };
    window.addEventListener("error", errorListener);

    let unHandledRejectionListener = (error: PromiseRejectionEvent) => {
      console.log("捕获到异常：", error.reason.message);
      notify(error.reason.message, { type: "error" });
    };

    window.addEventListener("unhandledrejection", unHandledRejectionListener);

    return () => {
      window.removeEventListener("error", errorListener);
      window.removeEventListener(
        "unhandledrejection",
        unHandledRejectionListener
      );
    };
  }, []);

  return <Layout {...props} appBar={AppBar} menu={Menu} />;
};
