import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field, isNotRequired } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import { BooleanTypeChoices, StatusTypeChoices } from "../../model/Core";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import { UserWalletTypeChoices } from "../../model/UserWallet";
import {
  ExchangeOrderDirectionChoices,
  ExchangeOrderStatusChoices,
  ExchangeOrderTypeChoices,
} from "../../model/exchange";
import { MyDatagrid } from "../../components/MyDatagrid";
import { userField } from "../../utils/field/userField";
import CollectionsIcon from "@mui/icons-material/Collections";

const fields: MyField<API.Coin>[] = [
  Field.text({
    label: "id",
    source: "id",
    maxWidth: 70,
  }),
  Field.ref({
    label: "NTF",
    source: "ntfId",
    isRequired: true,
    isList: true,
    isFilter: false,
    reference: "nftGoods",
    referenceLabel: "title",
    originSource: "id",
    translateLabel: (record: any) => record?.title?.[0].value,
  }),
  Field.select({
    label: "水军",
    source: "isMyUser",
    maxWidth: 50,
    isFilter: true,
    choices: BooleanTypeChoices,
  }),
  userField(),
  Field.amount({
    label: "价格",
    source: "price",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
];

export const MyNftOrderTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        filterDefaultValues={{ isMyUser: false }}
        rowActions={[]}
        tableActions={<></>}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: CollectionsIcon,
};

export default resource;
