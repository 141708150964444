import { buildField } from "../Field";
import { MyField } from "../../components/types";
import MyArrayField from "../../components/MyArrayField";

export interface ArrayArgs {
  label: string;
  source: string;
  transform?: (record?: any) => any;
}

export function array(args: ArrayArgs, field?: MyField) {
  return buildField(
    {
      label: args.label,
      source: args.source,
      isFilter: false,
      component: MyArrayField,
      minWidth: 200,
      props: {
        transform: args.transform,
      },
    },
    field
  );
}
