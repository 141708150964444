import { useCreate, useNotify } from "react-admin";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { formatDateTime, template } from "../../../utils/formatDateTime";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";

import { green } from "@mui/material/colors";
import { TimePicker } from "@mui/x-date-pickers";

type AddRobotLineProps = {
  exchangeCoins: any[];
  isLoading: boolean;
  onChange: () => void;
};

export function AddRobotLine({
  exchangeCoins,
  isLoading,
  onChange,
}: AddRobotLineProps) {
  const notify = useNotify();
  const [formData, setFormData] = useState({
    symbol: "",
    startTime: dayjs(),
    endTime: dayjs(),
    price: "",
    delayMinusBack: 1,
  });
  const handleChange = (field: string, value: any) => {
    setFormData({ ...formData, [field]: value });
  };
  const handleTimeChange = (field: string, value: dayjs.Dayjs) => {
    let currentDate = dayjs();
    currentDate = currentDate
      .set("hour", value.get("hour"))
      .set("minute", value.get("minute"))
      .set("second", value.get("second"))
      .set("millisecond", 0);

    const v = currentDate.format(template);
    setFormData({ ...formData, [field]: v });
  };
  const [create, createState] = useCreate<any>("robotLines", {
    data: formData,
  });
  useEffect(() => {
    if (createState.error) {
      onChange();
      notify(createState.error + "", { autoHideDuration: 5000, type: "error" });
    }
  }, [createState.error]);

  useEffect(() => {
    if (createState.isSuccess) {
      onChange();
      notify("提交成功", { autoHideDuration: 5000, type: "success" });
    }
  }, [createState.isSuccess]);

  async function doSubmit(e: React.FormEvent<HTMLFormElement>) {
    await create("robotLines", {
      data: {
        ...formData,
        startTime: formatDateTime(formData.startTime),
        endTime: formatDateTime(formData.endTime),
      },
    });
    e.stopPropagation();
  }

  return (
    <Card sx={{ marginTop: "16px" }}>
      <CardHeader title={"控线机器人"} />

      <CardContent>
        <Stack
          component="form"
          sx={{
            width: "25ch",
          }}
          onSubmit={(e) => doSubmit(e)}
          id={"submit"}
          spacing={2}
          noValidate
          autoComplete="off"
        >
          <FormControl variant="outlined">
            <Autocomplete
              loading={isLoading}
              disablePortal
              onChange={(event, newValue) => handleChange("symbol", newValue)}
              id="symbol"
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="交易对" variant="outlined" />
              )}
              options={exchangeCoins?.map((item) => item.symbol) || []}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              label={"开始时间"}
              value={formData.startTime}
              onChange={(newValue: any) =>
                handleTimeChange("startTime", newValue)
              }
              renderInput={(params) => (
                <TextField variant={"outlined"} id="startTime" {...params} />
              )}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TimePicker
              ampm={false}
              openTo="hours"
              views={["hours", "minutes", "seconds"]}
              inputFormat="HH:mm:ss"
              mask="__:__:__"
              label={"结束时间"}
              value={formData.endTime}
              onChange={(newValue: any) =>
                handleTimeChange("endTime", newValue)
              }
              renderInput={(params) => (
                <TextField variant={"outlined"} id="endTime" {...params} />
              )}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              label={"价格(变动点位)"}
              type="number"
              id="price"
              variant="outlined"
              onChange={(e) => handleChange("price", e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
          <FormControl variant="outlined">
            <TextField
              label={"延迟恢复(分钟)"}
              type="number"
              id="delayMinusBack"
              variant="outlined"
              onChange={(e) => handleChange("delayMinusBack", e.target.value)}
              defaultValue={1}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </FormControl>
        </Stack>
      </CardContent>
      <CardActions>
        <Box sx={{ m: 1, position: "relative" }}>
          <Button
            size="small"
            variant="contained"
            form={"submit"}
            type="submit"
          >
            提交
          </Button>
          {createState.isLoading && (
            <CircularProgress
              size={24}
              sx={{
                color: green[500],
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          )}
        </Box>
      </CardActions>
    </Card>
  );
}
