import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import WebIcon from "@mui/icons-material/Web";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "页面路径",
    source: "path",
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
          </WrapperField>
        }
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} maxWidth={"100%"} />;
  },
  edit: () => {
    return <MyEdit fields={fields} maxWidth={1000} />;
  },
  icon: WebIcon,
};

export default resource;
