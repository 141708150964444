import { EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import { BooleanTypeChoices } from "../../model/Core";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

const fields: MyField<API.ExchangeCoin>[] = [
  Field.text({
    label: "id",
    source: "id",
    isList: false,
    isCreate: false,
    isFilter: false,
    isEdit: false,
    isUpdate: false,
  }),
  Field.text(
    {
      label: "交易对",
      source: "symbol",
    },
    {
      isCreate: false,
      isUpdate: false,
    }
  ),
  Field.text(
    {
      label: "交易币种",
      source: "coinSymbol",
      // localLabelSource: "coinSymbol",
      // originSource: "coinSymbol",
      // reference: "coins",
      // referenceLabel: "symbol",
    },
    {
      isUpdate: false,
      isList: false,
    }
  ),
  Field.text(
    {
      label: "结算币种",
      source: "baseSymbol",
      // localLabelSource: "baseSymbol",
      // originSource: "baseSymbol",
      // reference: "coins",
      // referenceLabel: "symbol",
    },
    {
      isUpdate: false,
      isList: false,
    }
  ),
  Field.ref({
    label: "交易区域",
    source: "zone",
    originSource: "zone",
    reference: "zones",
    referenceLabel: "name",
  }),
  Field.editSelect({
    label: "是否启用",
    source: "enable",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否可见",
    source: "visible",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否可交易",
    source: "exchangeable",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否允许卖",
    source: "enableSell",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否允许买",
    source: "enableBuy",
    choices: BooleanTypeChoices,
  }),
  Field.editNumber({
    label: "最低杠杆倍数",
    source: "leverageMin",
  }),
  Field.editNumber({
    label: "最高杠杆倍数",
    source: "leverageMax",
  }),
  Field.editNumber({
    label: "机器人类型",
    source: "robotType",
  }),
  Field.editNumber({
    label: "交易手续费",
    source: "fee",
  }),
  Field.editNumber({
    label: "排序",
    source: "sort",
  }),
  Field.editNumber({
    label: "交易币精度",
    source: "coinScale",
  }),
  Field.editNumber({
    label: "结算币精度",
    source: "baseCoinScale",
  }),
  Field.editNumber({
    label: "最低卖价",
    source: "minSellPrice",
  }),
  Field.editNumber({
    label: "最高买价",
    source: "maxBuyPrice",
  }),
  Field.editSelect({
    label: "是否启用市价卖",
    source: "enableMarketSell",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否启用市价买",
    source: "enableMarketBuy",
    choices: BooleanTypeChoices,
  }),
  Field.editNumber({
    label: "最大允许同时交易的订单数",
    source: "maxTradingOrder",
  }),
  Field.editSelect({
    label: "是否允许合约交易",
    source: "enableContract",
    choices: BooleanTypeChoices,
  }),
  Field.editNumber({
    label: "最低合约杠杆倍数",
    source: "contractLeverageMin",
  }),
  Field.editNumber({
    label: "最高合约杠杆倍数",
    source: "contractLeverageMax",
  }),
  Field.editNumber({
    label: "最小成交额",
    source: "minTurnover",
  }),
  Field.editNumber({
    label: "最小下单量",
    source: "minVolume",
  }),
  Field.editNumber({
    label: "最大下单量",
    source: "maxVolume",
  }),
  Field.dateTime(
    {
      label: "创建时间",
      source: "createdDate",
    },
    {
      isList: false,
    }
  ),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        fields={fields}
        rowActions={[<EditButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
          </WrapperField>
        }
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: CurrencyExchangeIcon,
};

export default resource;
