import { MyField } from "../../components/types";
import { buildField } from "../Field";
import { LocalReferenceField } from "../../components/LocalReferenceField";
import { ref, RefArgs } from "./ref";

export interface LocalRefArgs extends RefArgs {
  /**
   * 当前表显示字段, 例如: "用户"
   */
  localLabelSource?: string;
}

export const localRef = (args: LocalRefArgs, field?: MyField): MyField => {
  let localLabelSource = args.localLabelSource || "name";
  let fields = { ...ref(args, field) };
  return buildField(
    {
      ...fields,
      component: LocalReferenceField,
      props: { ...fields.props, localLabelSource, link: "show" },
      editProps: { ...fields.editProps, localLabelSource },
      filterProps: { ...fields.filterProps, localLabelSource },
    },
    field
  );
};
