import { useInput } from "ra-core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Button, CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import { IconButton, Input, InputAdornment, InputLabel } from "@mui/material";
import { Locales, LocalesValue } from "../model/Core";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";

export interface MyArrayInputProps extends CommonInputProps {
  className?: string;
}

export function MyArrayInput(props: MyArrayInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;
  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { name, value = [], ref } = field;

  useEffect(() => {
    if (value === "") {
      field.onChange([]);
    }
  }, [value]);

  const arrayValue = useMemo(() => {
    return value as string[];
  }, [field, field.value]);

  function changeValue(index: number, value: string) {
    const newValue = [...arrayValue];
    newValue[index] = value;

    field.onChange(newValue);
  }

  function add() {
    const newValue = [...arrayValue, ""];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...arrayValue];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box p={"8px 0"}>
      <InputLabel>{label}</InputLabel>
      <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
        {arrayValue.map((item, index) => {
          return (
            <Input
              key={index}
              value={item}
              onChange={(e) => changeValue(index, e.target.value)}
              endAdornment={
                <IconButton onClick={() => removeByIndex(index)}>
                  <RemoveCircleSharpIcon />
                </IconButton>
              }
            />
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        <Button
          size={"medium"}
          onClick={() => add()}
          startIcon={<AddCircleIcon />}
          label={"添加"}
        />
      </Box>
    </Box>
  );
}
