import { buildField } from "../Field";
import { MyField } from "../../components/types";
import { NumberField, TextField, TextInput } from "react-admin";
import { base, BaseArgs } from "./base";
import { TextArgs } from "./text";

export interface NumberArgs extends BaseArgs {}

const defaultArgs: Partial<NumberArgs> = {
  isRequired: false,
  fullWidth: false,
};

export function number(args: NumberArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: NumberField,
      isFilter: false,
      sortable: false,
      isEdit: false,
      isUpdate: false,
    },
    field
  );
}
