// @ts-ignore
/* eslint-disable */
import { request } from '../../../utils/request';

/** 此处后端没有提供注释 POST /userWallet/deduct */
export async function deduct(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.deductParams,
  options?: { [key: string]: any },
) {
  return request<string>('/userWallet/deduct', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 此处后端没有提供注释 POST /userWallet/recharge */
export async function recharge(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.rechargeParams,
  options?: { [key: string]: any },
) {
  return request<string>('/userWallet/recharge', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
