import { useInput } from "ra-core";
import * as React from "react";
import { useEffect, useMemo } from "react";
import { Button, CommonInputProps } from "react-admin";
import Box from "@mui/material/Box";
import {
  Divider,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
} from "@mui/material";
import { SwiperValue } from "../model/Core";
import RemoveCircleSharpIcon from "@mui/icons-material/RemoveCircleSharp";
import remove from "lodash/remove";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ImageUpload } from "./ImageUpload";
import { isEmpty } from "lodash";

export interface MyImageSwipersValueInputProps extends CommonInputProps {
  className?: string;
}

export function MyImageSwipersValueInput(props: MyImageSwipersValueInputProps) {
  const {
    className,
    defaultValue = "",
    label,
    format,
    helperText,
    onBlur,
    onChange,
    parse,
    resource,
    source,
    validate,
    ...rest
  } = props;

  const {
    field,
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
    id,
    isRequired,
  } = useInput({
    defaultValue,
    format,
    parse,
    resource,
    source,
    type: "text",
    validate,
    onBlur,
    onChange,
    ...rest,
  });

  const { value = [] } = field;

  useEffect(() => {
    if (value === "") {
      field.onChange([]);
    }
  }, [value]);

  const swiperValue = useMemo(() => {
    if (value === undefined || value === null || value === "") {
      return [];
    }
    return value as SwiperValue[];
  }, [field, field.value]);

  function changeLink(index: number, link: string) {
    const newValue = [...swiperValue];
    newValue[index].link = link;

    field.onChange(newValue);
  }

  function changeUrl(index: number, url: string) {
    const newValue = [...swiperValue];
    newValue[index].url = url;

    field.onChange(newValue);
  }

  function add() {
    const newValue = [...swiperValue, {}];
    field.onChange(newValue);
  }

  function removeByIndex(index: number) {
    const newValue = [...swiperValue];
    remove(newValue, (item, i) => i === index);
    field.onChange(newValue);
  }

  return (
    <Box p={"8px 0"} width={"100%"}>
      <InputLabel>{label}</InputLabel>
      <Box
        display={"flex"}
        alignItems={"start"}
        gap={"16px"}
        flexDirection={"column"}
      >
        {swiperValue.map((item, index) => {
          return (
            <Paper
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                padding: "8px",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "left",
              }}
            >
              <Box sx={{ width: "max-content", "& img": { width: "64px" } }}>
                <ImageUpload
                  defaultImage={item.url}
                  id={`${source}-${index}`}
                  onSuccess={(e) => e && !isEmpty(e) && changeUrl(index, e[0])}
                />
              </Box>
              <Input
                fullWidth
                value={item.link || ""}
                placeholder={"输入链接"}
                onChange={(e) => changeLink(index, e.target.value)}
                endAdornment={
                  <IconButton onClick={() => removeByIndex(index)}>
                    <RemoveCircleSharpIcon />
                  </IconButton>
                }
              />
            </Paper>
          );
        })}
      </Box>
      <Box p={"4px 0"}>
        <Button
          size={"medium"}
          onClick={() => add()}
          startIcon={<AddCircleIcon />}
          label={"添加"}
        />
      </Box>
    </Box>
  );
}
