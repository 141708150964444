import React from "react";
import { MyLocalesValueInput } from "../../components/MyLocalesValueInput";
import { MyImageSwipersValueInput } from "../../components/MyImageSwipersValueInput";
import { NumberInput, required, TextInput } from "react-admin";
import { format, parse } from "../../utils/percent";
import InputAdornment from "@mui/material/InputAdornment";
import { MyRichTextInput } from "../../components/MyRichTextInput";

export const EmailSetting: React.FC = (props) => {
  return (
    <>
      <TextInput label="smtp地址" source="setting.host" validate={required()} />
      <NumberInput
        label="smtp端口"
        source="setting.port"
        validate={required()}
        step={1}
      />
      <TextInput
        label="smtp用户名"
        source="setting.username"
        validate={required()}
      />
      <TextInput
        label="smtp密码"
        source="setting.password"
        validate={required()}
      />
      <TextInput
        label="发送邮件地址"
        source="setting.sendEmailAddress"
        validate={required()}
      />
      <MyLocalesValueInput label={"邮件标题"} source={"setting.subject"} />
      <MyRichTextInput label={"邮件内容"} source={"setting.htmlContent"} />
    </>
  );
};
