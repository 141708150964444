import omit from "lodash/omit";
import {
  DateField,
  maxLength,
  minLength,
  required,
  SelectInput,
  TextField,
  TextInput,
  TextInputProps,
  WrapperField,
} from "react-admin";
import { UserManagerApi } from "../../api/UserManagerApi";
import { EditSelectField } from "../../components/EditSelectField";
import { MyField } from "../../components/types";
import { UnLockButton } from "../../components/UnLockButton";
import { Admin } from "../../model/Admin";
import { BooleanTypeChoices, StatusTypeChoices } from "../../model/Core";
import { Field } from "../../utils/Field";
import React from "react";
import { MyDatagrid } from "../../components/MyDatagrid";
import AmountButton from "./AmountButton";

function MyTextInput(props: TextInputProps & { localLabelSource: string }) {
  return <TextInput {...omit(props, "localLabelSource")} />;
}

export let fields: MyField<Admin>[];
fields = [
  Field.objectId({
    label: "用户ID",
    isList: false,
  }),
  Field.localRef(
    {
      label: "uid",
      source: "uid",
      isFilter: true,
      isEdit: true,
      isCreate: false,
      isUpdate: false,
      reference: "users",
      referenceLabel: "uid",
      originSource: "id",
      localLabelSource: "uid",
    },
    {
      filterProps: {
        source: "uid",
      },
      editComponent: MyTextInput,
    }
  ),
  Field.text({
    label: "邀请码",
    source: "inviteCode",
    isEdit: true,
    isCreate: false,
    isUpdate: false,
  }),
  Field.editSelect({
    label: "是否代理商",
    source: "isAgent",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否锁定账户",
    source: "isAccountLock",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否水军",
    source: "isMyUser",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否限制币种交易",
    source: "enableSymbolTradeLimit",
    choices: BooleanTypeChoices,
    isFilter: false,
  }),
  Field.refArr({
    label: "限制币种",
    source: "symbolTradeLimitList",
    reference: "coins",
    referenceLabel: "symbol",
    originSource: "symbolTradeLimitList",
    optionValue: "symbol",
    optionText: "symbol",
    isFilter: false,
    isList: false,
  }),
  Field.editAmount({
    label: "最小交易金额(小于等于0则不限制)",
    source: "minTradeAmount",
  }),
  Field.text({
    label: "账号",
    source: "name",
    isEdit: true,
    isCreate: true,
    isUpdate: true,
  }),
  Field.text({
    label: "邮箱",
    source: "email",
    isUpdate: false,
  }),
  Field.text({
    label: "电话",
    source: "phone",
    isUpdate: false,
  }),
  {
    label: "备注",
    source: "desc",
    sortable: false,
    isPrimary: true,
    isFilter: false,
    component: TextField,
    props: {},
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [maxLength(512)],
    },
  },
  {
    label: "密码",
    source: "password",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "超级密码",
    source: "adminPassword",
    isList: false,
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: TextInput,
    editProps: {
      validate: [minLength(4), maxLength(25)],
    },
  },
  {
    label: "注册时间",
    sortable: true,
    source: "createdDate",
    component: DateField,
    props: {
      showTime: true,
    },
  },
  {
    label: "状态",
    source: "status",
    component: EditSelectField,
    isFilter: false,
    props: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    filterProps: {
      choices: StatusTypeChoices,
      alwaysOn: true,
    },
    isCreate: true,
    isEdit: true,
    isUpdate: true,
    editComponent: SelectInput,
    editProps: {
      choices: StatusTypeChoices,
      validate: [required()],
    },
  },
  {
    label: "登录锁定",
    source: "lock",
    component: WrapperField,
    isFilter: true,
    props: {
      children: (
        <UnLockButton onClick={async (r: any) => UserManagerApi.unlock(r.id)} />
      ),
    },
  },
  Field.ref({
    label: "电报机器人",
    source: "telBotId",
    reference: "telBotConfigs",
    referenceLabel: "desc",
    originSource: "telBotId",
    isFilter: false,
  }),
];

export const ChildUserTable = () => {
  return <MyDatagrid fields={fields} rowActions={[]} />;
};
