import Big from "big.js";

export enum FundTermType {
  CLOSE_END = "CLOSE_END",
}

export const FundTermTypeChoices = [
  {
    id: FundTermType.CLOSE_END,
    name: "封闭型基金",
  },
];

export interface Tier {
  /**
   * 最高额度
   */
  maxValue?: string;

  /**
   * 收益率年化百分比
   */
  yieldRate: string;

  /**
   * -1 表示无限大
   */
  yieldRateMax: string;
}

export function compareTiers(a: Tier, b: Tier): number {
  const aValue = a.maxValue == null ? new Big(-1) : new Big(a.maxValue);
  const bValue = b.maxValue == null ? new Big(-1) : new Big(b.maxValue);
  return aValue.cmp(bValue);
}

export enum FundTermPlanStatus {
  INIT = "INIT",
  START = "START",
  PROGRESS = "PROGRESS",
  CLOSE_PROGRESS = "CLOSE_PROGRESS",
  END = "END",
}

export const FundTermPlanStatusChoices = [
  { id: FundTermPlanStatus.INIT, name: "初始化" },
  { id: FundTermPlanStatus.START, name: "开始" },
  { id: FundTermPlanStatus.PROGRESS, name: "进行中" },
  { id: FundTermPlanStatus.CLOSE_PROGRESS, name: "结束中" },
  { id: FundTermPlanStatus.END, name: "已结束" },
];

export enum FundStrategiesType {
  ARBITRAGE = "ARBITRAGE",
  MIXED = "MIXED",
}

export const FundStrategiesTypeChoices = [
  { id: FundStrategiesType.ARBITRAGE, name: "套利" },
  { id: FundStrategiesType.MIXED, name: "混合" },
];
