import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import { NftGoodsStatusChoices } from "../../model/nft";
import SavingsIcon from "@mui/icons-material/Savings";
import { BooleanTypeChoices } from "../../model/Core";

const fields: MyField[] = [
  Field.objectId(),
  Field.editSelect({
    label: "是否开启",
    source: "enable",
    choices: BooleanTypeChoices,
  }),
  Field.editSelect({
    label: "是否可以赎回",
    source: "enableRedeem",
    choices: BooleanTypeChoices,
  }),
  Field.text({
    label: "币种",
    source: "coinSymbol",
  }),
  Field.editNumber({
    label: "精度",
    source: "coinScale",
  }),
  Field.editNumber({
    label: "排序",
    source: "sort",
  }),
  Field.tiers({
    label: "层级设置",
    source: "tiers",
  }),
  Field.editAmount({
    label: "总限制",
    source: "allLimit",
  }),
  Field.amount({
    label: "已经售出",
    source: "allNumber",
  }),
  Field.editAmount({
    label: "单用户限制",
    source: "userLimit",
  }),
  Field.amount({
    label: "总利息",
    source: "historyInterest",
  }),
  Field.amount({
    label: "总显示利息",
    source: "showHistoryInterest",
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
          </WrapperField>
        }
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} />;
  },
  edit: () => {
    return <MyEdit fields={fields} />;
  },
  icon: SavingsIcon,
};

export default resource;
