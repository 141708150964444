import { DeleteButton, EditButton, WrapperField } from "react-admin";
import { MyList } from "../../components/MyList";
import { MyField } from "../../components/types";
import { Field } from "../../utils/Field";
import React from "react";
import MyCreate from "../../components/MyCreate";
import MyEdit from "../../components/MyEdit";
import ClassIcon from "@mui/icons-material/Class";
import {
  DataGridProProps,
  gridFilteredDescendantCountLookupSelector,
  GridRenderCellParams,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro";
import { Box, BoxProps, IconButton } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const fields: MyField[] = [
  Field.text({
    label: "ID",
    source: "id",
    isCreate: false,
    isUpdate: false,
    isEdit: false,
    isFilter: false,
  }),
  Field.text({
    label: "代号",
    source: "key",
    isFilter: false,
  }),
  Field.ref({
    label: "父节点",
    source: "parentId",
    isRequired: false,
    isList: false,
    isFilter: false,
    reference: "docCategories",
    referenceLabel: "key",
    originSource: "parentId",
  }),
  Field.localesValue({
    label: "标题",
    source: "title",
    fullWidth: true,
    isFilter: false,
  }),
  Field.dateTime({
    label: "创建时间",
    source: "createdDate",
  }),
  Field.dateTime({
    label: "更新时间",
    source: "updateDate",
  }),
];

function CustomGridTreeDataGroupingCell(props: GridRenderCellParams) {
  const { id, field, rowNode } = props;
  const apiRef = useGridApiContext();
  const filteredDescendantCountLookup = useGridSelector(
    apiRef,
    gridFilteredDescendantCountLookupSelector
  );
  const filteredDescendantCount =
    filteredDescendantCountLookup[rowNode.id] ?? 0;

  const handleClick: BoxProps["onClick"] = (event) => {
    apiRef.current.setRowChildrenExpansion(id, !rowNode.childrenExpanded);
    apiRef.current.setCellFocus(id, field);
    event.stopPropagation();
  };

  return (
    <Box sx={{ ml: rowNode.depth * 2 }} onClick={handleClick}>
      {filteredDescendantCount > 0 ? (
        <IconButton size={"small"}>
          {!rowNode.childrenExpanded ? (
            <KeyboardArrowRightIcon />
          ) : (
            <KeyboardArrowDownIcon />
          )}
        </IconButton>
      ) : (
        <Box width={"20px"} display="inline-block"></Box>
      )}
      {props.row.id}
    </Box>
  );
}

export const groupingColDef: DataGridProProps["groupingColDef"] = {
  headerName: "ID",
  width: 160,
  renderCell: (params) => <CustomGridTreeDataGroupingCell {...params} />,
};

function transform(p: any) {
  if (p.parentId === "") {
    delete p.parentId;
  }
  return p;
}

const resource = {
  list: () => {
    return (
      <MyList
        pagination={false}
        fields={fields}
        rowActions={[<EditButton />, <DeleteButton />]}
        tableActions={
          <WrapperField>
            <EditButton />
            <DeleteButton />
          </WrapperField>
        }
        groupingColDef={groupingColDef}
        getTreeDataPath={(r: any) => {
          return r.path;
        }}
        isGroupExpandedByDefault={(r: any) => true}
        // groupingColDef={groupingColDef}
      />
    );
  },
  create: () => {
    return <MyCreate fields={fields} transform={transform} />;
  },
  edit: () => {
    return <MyEdit fields={fields} transform={transform} />;
  },
  icon: ClassIcon,
};

export default resource;
