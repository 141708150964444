import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  useMediaQuery,
} from "@mui/material";
import { GridRowParams } from "@mui/x-data-grid-pro";
import { isElement } from "lodash";
import * as React from "react";
import { ReactElement, useEffect, useRef, useState } from "react";
import { RecordContextProvider } from "react-admin";
import { findDOMNode } from "react-dom";

export type MyGridActionsCellItemProps = {
  row: any;
  button: ReactElement;
};

const MyGridActionsCellItem = (props: MyGridActionsCellItemProps) => {
  const { row, button, ...other } = props;
  const [actionDom, setActionDom] = useState<Element | undefined>();
  const buttonRef = useRef(null);
  const clonedElement = React.cloneElement(button);
  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );
  const [icon, setIcon] = useState<string | undefined>();

  const [label, setLabel] = useState<string | undefined>(button.props.label);

  useEffect(() => {
    let buttonDom = findDOMNode(buttonRef.current);
    if (!buttonDom || isXSmall) {
      return;
    }
    if (!icon) {
      // @ts-ignore
      let list = buttonRef?.current?.children?.[0]?.children;
      if (list && list.length > 1) {
        setIcon(list[0].outerHTML);
        // @ts-ignore
        let ele = buttonDom["querySelector"]("a,button");
        const label = ele.innerText || ele.getAttribute("aria-label");
        setLabel(label);
      }
    }

    if (isElement(buttonDom)) {
      // @ts-ignore
      let actionDom = buttonDom["querySelector"]("a,button");
      setActionDom(actionDom as Element);
    }
  }, [buttonRef, buttonRef.current, isXSmall]);

  if (isXSmall) {
    return (
      <MenuItem>
        <RecordContextProvider<any> value={row}>
          {clonedElement}
        </RecordContextProvider>
      </MenuItem>
    );
  }
  return (
    <>
      <MenuItem
        onClick={(e) => {
          if (buttonRef.current) {
            // @ts-ignore
            setTimeout(() => actionDom?.click(), 0);
          }
          e.stopPropagation();
        }}
        {...(other as any)}
      >
        <RecordContextProvider<any> value={row}>
          {icon && (
            <ListItemIcon>
              <div
                style={{ paddingTop: "4px", height: "22px" }}
                dangerouslySetInnerHTML={{ __html: icon }}
              />
            </ListItemIcon>
          )}
          <ListItemText>{label}</ListItemText>
        </RecordContextProvider>
      </MenuItem>
      <span
        ref={buttonRef}
        style={{
          visibility: "visible",
          width: 1,
          height: 1,
          overflow: "hidden",
          position: "absolute",
        }}
      >
        <RecordContextProvider<any> value={row}>
          {clonedElement}
        </RecordContextProvider>
      </span>
    </>
  );
};

export { MyGridActionsCellItem };
