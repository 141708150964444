import React from "react";
import { BooleanInput, NullableBooleanInput } from "react-admin";

export const DevSetting: React.FC = (props) => {
  return (
    <>
      <BooleanInput
        label={"钱包登陆"}
        name={"setting.walletAppLogin"}
        source={"setting.walletAppLogin"}
      />
    </>
  );
};
