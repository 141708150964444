import { MyField } from "../../components/types";
import { MyAmountField } from "../../components/MyAmountField";
import { base, BaseArgs } from "./base";

export interface AmountArgs extends BaseArgs {}

const defaultArgs: Partial<AmountArgs> = {
  isRequired: false,
  fullWidth: false,
  isFilter: false,
  sortable: false,
  isEdit: false,
  isUpdate: false,
};

export function amount(args: AmountArgs, field?: MyField) {
  return base(
    {
      ...defaultArgs,
      ...args,
    },
    {
      component: MyAmountField,
    },
    field
  );
}
