import { stringToNumber } from "./amountUtils";
import { isString } from "lodash";
import toNumber from "lodash/toNumber";
import isNumber from "lodash/isNumber";

export const parse = (value: string) => {
  return stringToNumber(value, 0.01);
};

export const format = (v: any) => {
  if (isString(v)) {
    v = toNumber(v);
  }
  if (!isNumber(v)) {
    return "";
  }
  return (v * 100)
    .toFixed(2)
    .replace(/.00$/g, "")
    .replace(/(\.[0-9])0$/g, "$1");
};
