import { useRecordContext, useResourceContext } from "ra-core";
import * as React from "react";
import { useEffect } from "react";
import { ButtonProps, useNotify, useRefresh, useUpdate } from "react-admin";
import { CircularProgress } from "@mui/material";
import { LoadingButton } from "../../components/LoadingButton";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import { NftGoodsStatus } from "../../model/nft";
import SellIcon from "@mui/icons-material/Sell";

interface Props {}

export type UpDownButtonProps = Props & ButtonProps;

export const UpDownButton = (props: UpDownButtonProps) => {
  const { ...rest } = props;
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const [update, { error, isError }] = useUpdate();
  const refresh = useRefresh();
  const notify = useNotify();

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      notify(error["message"] || error + "", { type: "error" });
      refresh();
    }
  }, [error, isError, notify, refresh]);

  const handleClick = async (record: any, resource: string) => {
    if (record.status === NftGoodsStatus.SELL) return;
    const status =
      NftGoodsStatus.UP === record.status
        ? NftGoodsStatus.DOWN
        : NftGoodsStatus.UP;

    await update(resource, {
      id: record.id,
      data: { ...record, status },
      previousData: record,
    });
  };

  if (!record) return null;

  const label = () => {
    switch (record.status) {
      case NftGoodsStatus.SELL:
        return "已售";
      case NftGoodsStatus.UP:
        return "下架";
      case NftGoodsStatus.DOWN:
        return "上架";
    }
  };
  return (
    <LoadingButton
      label={label()}
      disabled={NftGoodsStatus.SELL === record.status}
      doLoading={handleClick}
      resource={resource}
      {...(rest as any)}
      icon={(f, loading) => {
        if (loading) {
          return <CircularProgress size={18} color="info" />;
        } else {
          switch (record.status) {
            case NftGoodsStatus.SELL:
              return <SellIcon fontSize={"small"} />;
            case NftGoodsStatus.DOWN:
              return <ArrowCircleUpIcon fontSize={"small"} />;
            case NftGoodsStatus.UP:
              return <ArrowCircleDownIcon fontSize={"small"} />;
          }
        }
      }}
    />
  );
};
