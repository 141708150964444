import { fetchJson } from "../utils/fetch";
import { API_URL } from "../config";
import { Admin } from "../model/Admin";

export const AdminManagerApi = {
  unlock: async (id: string): Promise<Admin> => {
    const { data } = await fetchJson(`${API_URL}/admins/unlock/${id}`, {
      method: "POST",
    });
    return data;
  },
};
