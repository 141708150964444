import { RaRecord, useRecordContext, useResourceContext } from "ra-core";
import * as React from "react";
import {
  Button,
  ButtonProps,
  useListContext,
  useNotify,
  useRefresh,
} from "react-admin";
import { Lock } from "@mui/icons-material";
// import { RaRecord } from "ra-core/src/types";
import isFunction from "lodash/isFunction";

type IconCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType,
  loading: boolean
) => JSX.Element;

type LoadingCallback = <
  RecordType extends RaRecord | Omit<RaRecord, "id"> = RaRecord
>(
  record: RecordType,
  resource: string
) => Promise<void>;

interface Props {
  resource?: string;
  doLoading: LoadingCallback;
  icon?: IconCallback | JSX.Element;
}

export type LoadingButtonProps = Props & ButtonProps;

export const LoadingButton = (props: LoadingButtonProps) => {
  const {
    doLoading,
    disabled,
    icon = <Lock fontSize={"small"} />,
    ...rest
  } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const { isLoading } = useListContext(props);
  const refresh = useRefresh();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);

  const handleClick = async () => {
    setLoading(true);
    try {
      await doLoading(record, resource);
    } catch (e) {
      // @ts-ignore
      notify(e["message"] || error + "", { type: "error" });
    } finally {
      refresh();
      setLoading(false);
    }
  };
  let curIcon: JSX.Element = isFunction(icon)
    ? icon(record, isLoading || loading)
    : icon;

  if (record) {
    return (
      <Button
        size={"small"}
        onClick={handleClick}
        disabled={disabled || loading}
        {...(rest as any)}
      >
        {curIcon}
      </Button>
    );
  } else {
    return (
      <Button
        size={"small"}
        onClick={handleClick}
        disabled={disabled || loading}
        {...(rest as any)}
      >
        {curIcon}
      </Button>
    );
  }
};
